import * as React from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import type { Rule } from '@mentimeter/ragnar-styled';
import { addUnit } from '@mentimeter/ragnar-utils';
import { forwardRef } from 'react';
import { inputRule, type InputRuleStatus } from '../utils/inputRule';

type ValueT = string | number;

export interface OptionT
  extends React.DetailedHTMLProps<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  > {
  children: string;
  value?: ValueT | undefined;
  disabled?: boolean | undefined;
}

const rule: Rule<{
  status?: InputRuleStatus | undefined;
  disabled?: boolean | undefined;
  inputSize?: 'default' | 'compact' | undefined;
}> = ({ theme, inputSize = 'default', status }) => {
  const size = {
    default: {
      paddingTop: addUnit(theme.kosmosSpacing.space4),
      paddingBottom: addUnit(theme.kosmosSpacing.space4),
      paddingLeft: addUnit(theme.kosmosSpacing.space4),
      paddingRight: addUnit(theme.kosmosSpacing.space8),
      borderRadius: addUnit(theme.kosmosBorderRadius.xl),
      fontSize: addUnit(theme.kosmosFontSize[100]),
      lineHeight: addUnit(theme.kosmosLineHeight.fixed[125]),
    },
    compact: {
      paddingTop: addUnit(theme.kosmosSpacing.space3),
      paddingBottom: addUnit(theme.kosmosSpacing.space3),
      paddingLeft: addUnit(theme.kosmosSpacing.space2),
      paddingRight: addUnit(theme.kosmosSpacing.space6),
      borderRadius: addUnit(theme.kosmosBorderRadius.lg),
      fontSize: addUnit(theme.kosmosFontSize['87.5']),
      lineHeight: addUnit(theme.kosmosLineHeight.fixed[100]),
    },
  };

  const backgroundPosition = {
    default: {
      backgroundPosition: `right ${addUnit(theme.kosmosSpacing.space3)} center`,
    },
    compact: {
      backgroundPosition: `right ${addUnit(theme.kosmosSpacing.space0)} center`,
    },
  };

  const svg = encodeURIComponent(
    `<svg width="24" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M.188 7.15C.084 7.06 0 6.866 0 6.74v-.246C0 6.22.232 6 .5 6h.248c.14 0 .335.075.432.162L3.5 8.25l2.32-2.088c.1-.09.287-.162.432-.162H6.5c.276 0 .5.23.5.494v.245c0 .136-.087.322-.188.41L3.5 9.987.188 7.148zm0-4.31L3.5 0l3.312 2.84c.1.086.188.272.188.41v.244c0 .265-.224.494-.5.494h-.248c-.145 0-.332-.072-.432-.16L3.5 1.75 1.18 3.827c-.097.087-.293.16-.432.16H.5c-.268 0-.5-.22-.5-.493V3.25c0-.13.084-.322.188-.41z" fill="${theme.colors.text}" fill-rule="evenodd" /></svg>`,
  );
  return {
    ...inputRule({ theme, status }),
    ...(size[inputSize] || size.default),
    ...(backgroundPosition[inputSize] || backgroundPosition.default),
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("data:image/svg+xml,${svg}")`,
    cursor: 'pointer',
    '> option, optgroup': {
      backgroundColor: theme.colors.bg,
      color: theme.colors.text,
      fontFamily: 'Helvetica',
    },
  };
};

const SelectItemStyled = styled('select', {
  displayName: 'Select',
})(rule, 'kosmosSpacing');

type SelectItemProps = React.ComponentProps<typeof SelectItemStyled>;

const SelectItem = forwardRef<HTMLElement | null, SelectItemProps>(
  (props, ref) => (
    <SelectItemStyled className="r-select" {...props} ref={ref} />
  ),
);

type SelectType = typeof SelectItem;

interface SelectI extends SelectType {
  Option: React.FC<OptionT>;
}

(SelectItem as SelectI).Option = (props) => <option {...props} />;

export type SelectItemT = React.ComponentProps<SelectI>;
export default SelectItem as SelectI;
