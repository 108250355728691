export function getTrackingURL(region: 'eu' | 'us'): string {
  let coreURL;
  if (region === 'eu') {
    coreURL =
      process.env.EU_TRACKING_URL ?? globalThis.__mentimeterEnv['NEXT_PUBLIC_EU_TRACKING_URL'];
  } else {
    coreURL =
      process.env.US_TRACKING_URL ?? globalThis.__mentimeterEnv['NEXT_PUBLIC_US_TRACKING_URL'];
  }
  if (!coreURL) {
    throw new Error(`No tracking URL for region ${region}`);
  }
  return coreURL;
}
