import {
  createCoreClient,
  createTrackingClient,
  setupHttp,
} from '@mentimeter/http-clients';
import { getLocalUser, getSessionToken } from '@mentimeter/user';

if (typeof window !== 'undefined') {
  const user = getLocalUser();

  const SERVICE_NAME = globalThis.__mentimeterEnv['NEXT_PUBLIC_SERVICE_NAME'] as string;
  const EU_TRACKING_URL = globalThis.__mentimeterEnv['NEXT_PUBLIC_EU_TRACKING_URL'] as string;
  const EU_CORE_URL = globalThis.__mentimeterEnv['NEXT_PUBLIC_EU_CORE_URL'] as string;
  const US_CORE_URL = globalThis.__mentimeterEnv['NEXT_PUBLIC_US_CORE_URL'] as string;
  const US_TRACKING_URL = globalThis.__mentimeterEnv['NEXT_PUBLIC_US_TRACKING_URL'] as string;

  const sessionToken = getSessionToken(user);
  const userToken = user?.token;
  const defaultRegion = user?.region ?? 'eu';

  setupHttp({
    serviceName: SERVICE_NAME,
    core: {
      defaultRegion,
      baseUrls: {
        eu: EU_CORE_URL,
        us: US_CORE_URL,
      },
      createClient(baseURL) {
        return createCoreClient({ baseURL, token: sessionToken });
      },
    },

    tracking: {
      defaultRegion,
      baseUrls: {
        eu: EU_TRACKING_URL,
        us: US_TRACKING_URL,
      },
      createClient(baseURL) {
        return createTrackingClient({ baseURL, token: userToken });
      },
    },
  });
}
