const path = require('path');
const { DEFAULT_LOCALE, LOCALES } = require('./src/constants.cjs');

process.env.I18NEXT_DEFAULT_CONFIG_PATH = './next-i18next.config.cjs';

module.exports = {
  i18n: {
    locales: LOCALES,
    defaultLocale: DEFAULT_LOCALE,
    localeDetection: false,
  },

  react: {
    useSuspense: false,
  },
  localePath: path.resolve('./locales'),
};
