/*
 *   Menti-display (Mentimeter Brand Font)
 */
const light = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Light.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Light.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Light.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Light.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Light.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '300' },
};

const lightItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-LightItalic.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-LightItalic.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-LightItalic.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-LightItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-LightItalic.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '300', fontStyle: 'italic' },
};

const regular = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Regular.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Regular.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Regular.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Regular.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Regular.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '400' },
};

const regularItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-RegularItalic.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-RegularItalic.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-RegularItalic.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-RegularItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-RegularItalic.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '400', fontStyle: 'italic' },
};

const semiBold = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBold.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBold.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBold.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBold.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBold.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '600' },
};

const semiBoldItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBoldItalic.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBoldItalic.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBoldItalic.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBoldItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-SemiBoldItalic.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '600', fontStyle: 'italic' },
};

const bold = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Bold.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Bold.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Bold.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Bold.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-Bold.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '700' },
};

const boldItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-BoldItalic.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-BoldItalic.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-BoldItalic.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-BoldItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-BoldItalic.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '700', fontStyle: 'italic' },
};

const extraBold = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBold.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBold.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBold.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBold.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBold.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '800' },
};

const extraBoldItalic = {
  files: [
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBoldItalic.eot',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBoldItalic.otf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBoldItalic.ttf',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBoldItalic.woff',
    'https://static.mentimeter.com/static/fonts/web/menti-display/1.4/MentiDisplay-ExtraBoldItalic.woff2',
  ],
  style: { fontDisplay: 'swap', fontWeight: '800', fontStyle: 'italic' },
};

export const MentiDisplay = {
  light,
  lightItalic,
  regular,
  regularItalic,
  semiBold,
  semiBoldItalic,
  bold,
  boldItalic,
  extraBold,
  extraBoldItalic,
};

export const mentiDisplayKeys = Object.keys(MentiDisplay) as Array<
  keyof typeof MentiDisplay
>;
