import React from 'react';
import Script, { type ScriptProps } from 'next/script';

interface OneTrustScriptProps {
  domainScript: string | undefined;
  oneTrustURL: string;
  /* eslint-disable react/no-unused-prop-types */ // False positive
  strategy?: ScriptProps['strategy'];
}

const ONETRUST_DEFAULT_URL = 'https://cdn.cookielaw.org'; // fallback for OneTrust CDN

// possible URLS for OneTrust:
// - https://cdn.cookielaw.org: default one, might have issues with files loading
// - https://static.stage-mentimeter.com/static/scripts/onetrust: our own AWS solution for storing onetrust files. could be stage or prod depending on env

const OneTrustScript = ({
  domainScript,
  oneTrustURL = ONETRUST_DEFAULT_URL,
  strategy = 'beforeInteractive',
}: OneTrustScriptProps) => {
  if (!domainScript) {
    return null;
  }

  return (
    <>
      <Script
        id="onetrust-autoblock"
        strategy={strategy}
        type="text/javascript"
        src={`${oneTrustURL}/consent/${domainScript}/OtAutoBlock.js`}
      />
      <Script
        id="onetrust-sdk"
        strategy={strategy}
        src={`${oneTrustURL}/consent/${domainScript}/otSDKStub.js`}
        data-document-language="true"
        type="text/javascript"
        data-domain-script={domainScript}
      />
      <Script
        id="onetrust-init"
        strategy={strategy}
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper() {
            if (typeof window === 'undefined') return;
            if (typeof CustomEvent === 'undefined') return;
            // we specifically create a new Event for catching banner load behavior in order to call methods attached to it
            var event = new CustomEvent('OneTrustBannerLoaded');
            window.dispatchEvent(event);
          }`,
        }}
      />
    </>
  );
};

export const OneTrustPreload = ({
  domainScript,
  oneTrustURL = ONETRUST_DEFAULT_URL,
}: OneTrustScriptProps) => {
  if (!domainScript) {
    return null;
  }

  return (
    <>
      <link
        rel="preload"
        href={`${oneTrustURL}/consent/${domainScript}/OtAutoBlock.js`}
        as="script"
      />
      <link
        rel="preload"
        href={`${oneTrustURL}/consent/${domainScript}/otSDKStub.js`}
        as="script"
      />
    </>
  );
};

export default OneTrustScript;
