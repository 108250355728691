import { colors } from '../colors';
import {
  blackBase,
  blackAlpha100,
  blackAlpha200,
  blackAlpha300,
  blackAlpha400,
  blackAlpha500,
  blackAlpha600,
  blackAlpha800,
  whiteBase,
  whiteAlpha100,
  whiteAlpha200,
  whiteAlpha300,
  whiteAlpha400,
  whiteAlpha500,
  whiteAlpha600,
  whiteAlpha700,
  whiteAlpha800,
  whiteAlpha900,
  gray500,
  gray600,
  gray800,
  blue50,
  blue100,
  blue500,
  blue800,
  blueDark500,
  green500,
  greenDark700,
  greenDark800,
  pinkDark600,
  pinkDark800,
  purpleDark500,
  purpleDark800,
  yellow500,
  blackAlpha900,
  blackAlpha700,
  yellowDark800,
  blue200,
  pinkDark200,
  pinkDark300,
  purpleDark700,
  greenDark500,
  pinkDark400,
  yellowLight400,
  yellowLight500,
  yellowLight600,
  pinkLight400,
  pinkLight500,
  pinkLight600,
  greenLight400,
  greenLight500,
  greenLight600,
  greenDark600,
} from '../palette';
import { dark } from './dark';
import type { ThemesT } from './theme.types';

/** IMPORTANT! Any changes to themes needs to be reflected in tailwind!
 * 1. Apply the same changes to the respective theme in kosmosTokensToPaletteMap.ts, in playground-web
 * 2. Run localhost:1000/tailwind (playground-web) with the theme settings and use "console.log(colorTokenMap)"
 * 3. Update the theme class in globals.css
 */
export const themes: ThemesT = {
  light: {
    colors: {
      ...colors,
    },
  },
  dark: { colors: dark },
  primary: {
    colors: {
      ...colors,
      bg: blue500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: whiteAlpha500,
      borderWeak: whiteAlpha300,
      borderWeaker: whiteAlpha300,
      borderStrong: whiteAlpha600,
      borderDisabled: whiteAlpha300,
      onBrand: blueDark500,
      onBrandWeakest: whiteBase,
      onDisabled: whiteAlpha400,
      onDisabledStrong: whiteAlpha600,
      onPrimary: blueDark500,
      primary: whiteBase,
      primaryWeakest: whiteAlpha500,
      disabled: whiteAlpha100,
      disabledStrong: whiteAlpha200,
      interactive: whiteBase,
      onInteractive: blue500,
      secondary: blue800,
      text: whiteBase,
      textWeak: whiteBase,
      textWeaker: whiteBase,
      textDisabled: whiteAlpha400,
      textLink: whiteBase,
      textPrimary: whiteBase,
      interactiveFocused: whiteAlpha700,
      // COMPONENT TOKENS
      buttonOutlineBgHover: blackAlpha200,
      buttonOutlineBgActive: blackAlpha400,
      buttonBrandBg: whiteBase,
      buttonBrandBgHover: whiteAlpha900,
      buttonBrandBgActive: whiteAlpha700,
      buttonBrandWeakBg: whiteAlpha200,
      buttonBrandWeakBgHover: whiteAlpha300,
      buttonBrandWeakBgActive: whiteAlpha400,
      buttonPrimaryBg: blue50,
      buttonPrimaryBgHover: blue100,
      buttonPrimaryBgActive: blue200,
      buttonPrimaryBgChecked: blue800,
      buttonPrimaryBgCheckedHover: blue800,
      buttonPrimaryBgCheckedActive: blue800,
      buttonPrimaryReverseBgChecked: blue800,
      buttonPrimaryReverseBgCheckedHover: blue800,
      buttonPrimaryReverseBgCheckedActive: blue800,
      buttonSecondaryBgHover: whiteAlpha400,
      buttonSecondaryBgActive: whiteAlpha500,
      buttonTertiaryBgChecked: blue800,
      buttonTertiaryBgCheckedHover: blue800,
      buttonTertiaryBgCheckedActive: blue800,
      inputBg: whiteAlpha300,
      inputBgActive: blue500,
    },
  },
  negative: {
    colors: {
      ...colors,
      bg: pinkDark600,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: whiteAlpha500,
      borderWeak: whiteAlpha300,
      borderWeaker: whiteAlpha200,
      borderStrong: whiteAlpha600,
      borderDisabled: whiteAlpha300,
      text: whiteBase,
      textWeak: whiteAlpha900,
      textWeaker: whiteAlpha800,
      textDisabled: whiteAlpha400,
      textLink: whiteBase,
      textPrimary: whiteBase,
      onBrand: blueDark500,
      onBrandWeakest: whiteBase,
      onDisabled: whiteAlpha400,
      onDisabledStrong: whiteAlpha600,
      neutral: whiteAlpha300,
      neutralWeakest: whiteAlpha200,
      primary: whiteBase,
      disabled: whiteAlpha100,
      disabledStrong: whiteAlpha200,
      interactive: whiteBase,
      onNeutral: whiteBase,
      onInteractive: blueDark500,
      secondary: pinkDark800,
      interactiveFocused: whiteAlpha700,
      // COMPONENT TOKENS
      buttonBrandBg: whiteBase,
      buttonBrandBgHover: whiteAlpha900,
      buttonBrandBgActive: whiteAlpha700,
      buttonBrandWeakBg: whiteAlpha200,
      buttonBrandWeakBgHover: whiteAlpha300,
      buttonBrandWeakBgActive: whiteAlpha400,
      buttonOutlineBgHover: whiteAlpha200,
      buttonOutlineBgActive: whiteAlpha300,
      buttonOutlineBgChecked: pinkLight400,
      buttonOutlineBgCheckedHover: pinkLight500,
      buttonOutlineBgCheckedActive: pinkLight600,
      buttonSecondaryBgHover: whiteAlpha400,
      buttonSecondaryBgActive: whiteAlpha500,
      buttonSecondaryBgChecked: pinkDark200,
      buttonSecondaryBgCheckedHover: pinkDark300,
      buttonSecondaryBgCheckedActive: pinkDark400,
      inputBg: whiteAlpha300,
      inputBgActive: pinkDark600,
    },
  },
  notice: {
    colors: {
      ...colors,
      bg: yellow500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: blackAlpha500,
      borderWeak: blackAlpha300,
      borderWeaker: blackAlpha200,
      borderStrong: blackAlpha600,
      borderDisabled: blackAlpha300,
      textWeaker: blackAlpha800,
      textDisabled: blackAlpha400,
      textLink: blackBase,
      textPrimary: blackBase,
      onBrand: whiteBase,
      onBrandWeakest: blackBase,
      onDisabled: blackAlpha400,
      onDisabledStrong: blackAlpha600,
      onPrimary: whiteBase,
      neutral: blackAlpha300,
      neutralWeakest: blackAlpha200,
      primary: blueDark500,
      disabled: blackAlpha100,
      disabledStrong: blackAlpha200,
      interactive: blueDark500,
      onNeutral: blackBase,
      onInteractive: whiteBase,
      secondary: yellowDark800,
      interactiveFocused: blackAlpha700,
      // COMPONENT TOKENS
      buttonBrandWeakBg: blackAlpha200,
      buttonBrandWeakBgHover: blackAlpha300,
      buttonBrandWeakBgActive: blackAlpha400,
      buttonOutlineBgChecked: yellowLight400,
      buttonOutlineBgCheckedHover: yellowLight500,
      buttonOutlineBgCheckedActive: yellowLight600,
      buttonSecondaryBgChecked: yellowLight400,
      buttonSecondaryBgCheckedHover: yellowLight500,
      buttonSecondaryBgCheckedActive: yellowLight600,
      inputBg: blackAlpha300,
      inputBgActive: yellow500,
    },
  },
  positive: {
    colors: {
      ...colors,
      bg: green500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: blackAlpha500,
      borderWeak: blackAlpha300,
      borderWeaker: blackAlpha300,
      borderStrong: blackAlpha600,
      borderDisabled: blackAlpha300,
      textDisabled: blackAlpha400,
      textWeaker: blackAlpha800,
      textLink: blackBase,
      textPrimary: blackBase,
      onBrandWeakest: blackBase,
      onDisabled: blackAlpha400,
      onDisabledStrong: blackAlpha600,
      onPrimary: whiteBase,
      neutral: blackAlpha300,
      neutralWeakest: blackAlpha200,
      primary: blueDark500,
      disabled: blackAlpha100,
      disabledStrong: blackAlpha200,
      interactive: blueDark500,
      onNeutral: blackBase,
      onInteractive: whiteBase,
      secondary: greenDark800,
      interactiveFocused: blackAlpha700,
      // COMPONENT TOKENS
      buttonBrandWeakBg: blackAlpha200,
      buttonBrandWeakBgHover: blackAlpha300,
      buttonBrandWeakBgActive: blackAlpha400,
      buttonOutlineBgChecked: greenLight400,
      buttonOutlineBgCheckedHover: greenLight500,
      buttonOutlineBgCheckedActive: greenLight600,
      buttonPrimaryBgChecked: greenDark500,
      buttonPrimaryBgCheckedHover: greenDark600,
      buttonPrimaryBgCheckedActive: greenDark700,
      buttonPrimaryReverseBgChecked: greenDark500,
      buttonPrimaryReverseBgCheckedHover: greenDark600,
      buttonPrimaryReverseBgCheckedActive: greenDark500,
      buttonSecondaryBgChecked: greenLight400,
      buttonSecondaryBgCheckedHover: greenLight500,
      buttonSecondaryBgCheckedActive: greenLight600,
      buttonTertiaryBgChecked: greenDark500,
      buttonTertiaryBgCheckedHover: greenDark600,
      buttonTertiaryBgCheckedActive: greenDark500,
      inputBg: blackAlpha300,
      inputBgActive: green500,
    },
  },
  info: {
    colors: {
      ...colors,
      bg: purpleDark500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: whiteAlpha500,
      borderWeak: whiteAlpha300,
      borderWeaker: whiteAlpha300,
      borderStrong: whiteAlpha600,
      borderDisabled: whiteAlpha300,
      onBrand: blueDark500,
      onBrandWeakest: whiteBase,
      onDisabled: whiteAlpha400,
      onDisabledStrong: whiteAlpha600,
      neutralWeakest: blackAlpha200,
      disabled: whiteAlpha100,
      disabledStrong: whiteAlpha200,
      interactive: whiteBase,
      onInteractive: blueDark500,
      secondary: purpleDark800,
      interactiveFocused: whiteAlpha700,
      // COMPONENT TOKENS
      buttonBrandBg: whiteBase,
      buttonBrandBgHover: whiteAlpha900,
      buttonBrandBgActive: whiteAlpha700,
      buttonBrandWeakBg: whiteAlpha200,
      buttonBrandWeakBgHover: whiteAlpha300,
      buttonBrandWeakBgActive: whiteAlpha400,
      buttonOutlineBgHover: whiteAlpha200,
      buttonOutlineBgActive: whiteAlpha300,
      buttonPrimaryBgChecked: purpleDark700,
      buttonPrimaryBgCheckedHover: purpleDark800,
      buttonPrimaryBgCheckedActive: purpleDark800,
      buttonPrimaryReverseBgChecked: purpleDark700,
      buttonPrimaryReverseBgCheckedHover: purpleDark800,
      buttonPrimaryReverseBgCheckedActive: purpleDark800,
      buttonSecondaryBgChecked: whiteAlpha200,
      buttonSecondaryBgCheckedHover: whiteAlpha300,
      buttonSecondaryBgCheckedActive: whiteAlpha400,
      buttonTertiaryBgChecked: purpleDark700,
      buttonTertiaryBgCheckedHover: purpleDark800,
      buttonTertiaryBgCheckedActive: purpleDark800,
      inputBg: whiteAlpha300,
      inputBgActive: purpleDark500,
    },
  },
  neutral: {
    colors: {
      ...colors,
      bg: gray500,
      bgStrong: blackAlpha200,
      surface: whiteAlpha100,
      surfaceRaised: whiteAlpha200,
      surfaceSunken: blackAlpha200,
      surfaceOverlay: whiteAlpha100,
      border: blackAlpha500,
      borderWeak: blackAlpha300,
      borderWeaker: blackAlpha300,
      borderStrong: blackAlpha600,
      borderDisabled: blackAlpha300,
      textWeak: blackAlpha900,
      textWeaker: blackAlpha800,
      textDisabled: blackAlpha400,
      textLink: blackBase,
      textPrimary: blackBase,
      onBrandWeakest: blackBase,
      onDisabled: blackAlpha400,
      onDisabledStrong: blackAlpha600,
      neutralWeakest: blackAlpha200,
      primary: blueDark500,
      primaryWeakest: blackAlpha500,
      neutral: gray600,
      disabled: blackAlpha100,
      disabledStrong: blackAlpha200,
      interactive: blueDark500,
      onNeutral: blackBase,
      onInteractive: whiteBase,
      secondary: gray800,
      interactiveFocused: blackAlpha700,
      // COMPONENT TOKENS
      buttonBrandWeakBg: blackAlpha200,
      buttonBrandWeakBgHover: blackAlpha300,
      buttonBrandWeakBgActive: blackAlpha400,
      inputBg: blackAlpha300,
      inputBgActive: gray500,
    },
  },
};
