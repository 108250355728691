import type { i18n as i18nT, InitOptions } from 'i18next';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import hoistNonReactStatic from 'hoist-non-react-statics';
import {
  addResourcesToInstance,
  getResourcesAsConfig,
} from './resourceBundlesHelper';

export interface NextI18NextInitConfig extends InitOptions {
  i18n?: any;
}

/**
 * Initializes a i18next instance.
 *
 * @example
 * useMentimeterI18n({react: {useSuspense: false}}, 'en-US')
 */
export const useMentimeterI18n = (
  config: NextI18NextInitConfig,
  language?: string,
): i18nT => {
  const instance = React.useMemo(() => i18n.createInstance(), []);
  const { isInitialized } = instance;

  React.useEffect(() => {
    if (language && isInitialized) {
      instance.changeLanguage(language);
    }
  }, [language, isInitialized, instance]);

  if (!isInitialized) {
    const { i18n: nextI18nConfig, ...configStripped } = config;

    if (configStripped.lng === undefined && language !== undefined) {
      configStripped.lng = language;
    }

    if (typeof configStripped.fallbackLng === 'undefined') {
      configStripped.fallbackLng = nextI18nConfig?.defaultLocale;
    }
    const resources = getResourcesAsConfig();
    instance.init({
      ...configStripped,
      resources,
    });
  } else {
    addResourcesToInstance(instance);
  }

  return instance as i18nT;
};

const I18nContext = React.createContext<i18nT | null>(null);

/**
 * Initializes the i18next instance used by mm packages.
 *
 * @example
 * <MentimeterI18nInitializer config={{react: {useSuspense: false}}} language={locale}>
 *    <App />
 * </MentimeterI18nInitializer>
 */
export const MentimeterI18nInitializer = ({
  children,
  config,
  language,
}: {
  children: React.ReactNode;
  config: NextI18NextInitConfig;
  language?: string;
}) => {
  const i18nextInstance = useMentimeterI18n(config, language);

  return (
    <I18nContext.Provider value={i18nextInstance}>
      {children}
    </I18nContext.Provider>
  );
};

/**
 * Helper HOC to wrap component with I18nextProvider using the correct i18nextInstance
 * This is added to access the correct instance in e.g. useTranslation in packages with their own translations.
 */
export const withMentimeterI18nProvider = <P extends Record<string, any>>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  const ComponentWithProvider = (props: P) => {
    const i18nextInstance = React.useContext(I18nContext);
    return i18nextInstance !== null ? (
      <I18nextProvider i18n={i18nextInstance}>
        <WrappedComponent {...props} />
      </I18nextProvider>
    ) : (
      <WrappedComponent {...props} />
    );
  };
  ComponentWithProvider.displayName = `withMentimeterI18nProvider(${displayName})`;

  hoistNonReactStatic(ComponentWithProvider, WrappedComponent);

  return ComponentWithProvider;
};
