import React, { useId } from 'react';
import type { ColorsEnum } from '@mentimeter/ragnar-colors';
import { Box } from '../Box/Box';
import { Text } from '../Text/Text';
import { Tooltip } from '../Tooltip/Tooltip';
import type { BadgeRibbonT } from '../Ribbon/badgeRibbonTypes';
import { BADGE_MAP } from './badgeMap';
import type { BadgeT } from './badge.types';

export const Badge = ({
  type,
  compact,
  description,
  text,
  bg,
  color,
  textTransform = 'capitalize',
  ...props
}: BadgeT) => {
  const referenceId = useId();
  const {
    color: colorFromMap,
    title: TitleFromMap,
    bg: bgFromMap,
    description: descriptionFromMap,
  } = BADGE_MAP[type];

  const tooltipDescription = description ? description : descriptionFromMap;

  return (
    <>
      <Box
        id={referenceId}
        flexDirection="row"
        alignItems="center"
        borderRadius="full"
        bg={bg || bgFromMap || 'primary'}
        aria-label={getBadgeLabel(type, text)}
        extend={() => ({
          userSelect: 'none',
        })}
        {...props}
      >
        {/* render text */}
        {typeof TitleFromMap === 'string' && (
          <Text
            fontWeight="semiBold"
            lineHeight="normal"
            color={color || colorFromMap || 'onPrimary'}
            textTransform={textTransform}
            mx={compact ? 'space1.5' : 'space2'}
            my={compact ? 'space0.5' : 'space1'}
            fontSize={compact ? '62.5' : '87.5'}
          >
            {type === 'text' && text ? text : TitleFromMap || 'Unknown'}
          </Text>
        )}
        {/* render icon */}
        {TitleFromMap && typeof TitleFromMap === 'function' && (
          <Box p={compact ? 'space1' : 'space1.5'}>
            <TitleFromMap
              size={compact ? 0 : 1}
              color={(color || colorFromMap || 'onPrimary') as ColorsEnum}
            />
          </Box>
        )}
      </Box>
      {tooltipDescription && (
        <Tooltip
          placement="top"
          referenceId={referenceId}
          description={tooltipDescription}
        />
      )}
    </>
  );
};

function getBadgeLabel(type: BadgeRibbonT, text: string | undefined) {
  const badgeTitle = text || BADGE_MAP[type]?.title;
  return typeof badgeTitle === 'string'
    ? `${badgeTitle} badge`
    : `${type} badge`;
}
