import { LocalStorage } from '@mentimeter/storage';
import type { UserResponseT } from '@mentimeter/http-clients';
import { SESSION_TOKEN_KEY, REGION_KEY } from '@mentimeter/user/session';

declare global {
  interface Window {
    Intercom: any;
  }
}

export const USER_LOCALSTORAGE_KEY = 'user';

export const getLocalUser = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  return LocalStorage.getJSONItem<UserResponseT>(USER_LOCALSTORAGE_KEY);
};

export const setLocalUser = (
  user: UserResponseT,
  options = { setSessionToken: true },
): void => {
  if (options.setSessionToken) {
    const sessionToken = user.session_token;
    if (sessionToken !== undefined) {
      delete user.session_token;
      LocalStorage.setItem({
        type: 'necessary',
        key: SESSION_TOKEN_KEY,
        value: sessionToken,
      });
      LocalStorage.setItem({
        type: 'necessary',
        key: REGION_KEY,
        value: user.region,
      });
    }
  }
  LocalStorage.setJSONItem<UserResponseT>({
    type: 'necessary',
    key: USER_LOCALSTORAGE_KEY,
    value: user,
  });
};

export const getSessionToken = (
  user?: UserResponseT | null,
): string | null | undefined => {
  if (typeof window === 'undefined') {
    return null;
  }
  const sessionToken = LocalStorage.getItem(SESSION_TOKEN_KEY);
  if (sessionToken !== null) {
    return sessionToken;
  } else if (
    user &&
    user.identifier !== undefined &&
    user.identifier !== null &&
    user.id !== undefined &&
    user.id !== null
  ) {
    return `${user.identifier}.${user.id}`;
  }
  return null;
};

export const getAuthorizationBearer = () => {
  return `Bearer ${getSessionToken()}`;
};

export const removeLocalUser = () => {
  LocalStorage.removeItem(USER_LOCALSTORAGE_KEY);
};

export const removeSessionToken = () => {
  LocalStorage.removeItem(SESSION_TOKEN_KEY);
  LocalStorage.removeItem(REGION_KEY);
};

export const intercomLogout = () => {
  try {
    window.Intercom('shutdown');
  } catch (_e) {
    // Maybe manually delete "intercom-" cookies?
  }
};

export const removeLocalUserAndSessionToken = async () => {
  removeSessionToken();
  removeLocalUser();
  await removeSessionCookies();
};

const removeSessionCookies = async () => {
  try {
    await fetch('/auth/api/logout', {
      method: 'POST',
    });
  } catch (error) {}
};
