import { reset, setup } from '@mentimeter/ragnar-reset';
import * as Fonts from '@mentimeter/ragnar-fonts';
import {
  createRenderer as _createRenderer,
  prefixer,
  fallback,
  normalizer,
} from '@mentimeter/ragnar-react';

function createRenderer() {
  // Configure Design System Renderer
  const renderer = _createRenderer({
    selectorPrefix: 'f-',
    mediaQueryOrder: [
      'screen and (min-width: 600px)',
      'screen and (min-width: 960px)',
      'screen and (min-width: 1200px)',
    ],
    plugins: [prefixer(), fallback(), normalizer()],
  });

  Object.keys(Fonts.MentiText).forEach((key) => {
    renderer.renderFont(
      'MentiText',
      Fonts.MentiText[key as keyof typeof Fonts.MentiText].files,
      Fonts.MentiText[key as keyof typeof Fonts.MentiText].style,
    );
  });

  Object.keys(Fonts.MentiDisplay).forEach((key) => {
    renderer.renderFont(
      'MentiDisplay',
      Fonts.MentiDisplay[key as keyof typeof Fonts.MentiDisplay].files,
      Fonts.MentiDisplay[key as keyof typeof Fonts.MentiDisplay].style,
    );
  });

  const selector = `body`;
  const extend = setup(selector);
  renderer.renderStatic(reset(extend));

  return renderer;
}

export default createRenderer;
