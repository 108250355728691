import * as React from 'react';
import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { combineRules } from '@mentimeter/ragnar-react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { BoxT } from '../Box/Box';
import { rule as boxRule } from '../Box/Box';

// Inherit the API from Box, but add some props
export interface ClickableA
  extends Pick<BoxT, 'bg' | 'borderRadius' | 'borderColor'> {
  href?: string | undefined;
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean | undefined;
  target?: '_blank' | undefined;
  rel?: 'noopener noreferrer';
  title?: string;
}

const clickableRule: Rule<ClickableA> = ({ theme, disabled, bg }) => {
  const generalStyling = {
    backgroundColor: bg,
    textDecoration: 'none',
    appearance: 'none',
    transition: `all ${theme.durations[1]}s ease`,
  };
  if (disabled)
    return {
      ...generalStyling,
      cursor: 'not-allowed',
      opacity: theme.opacity.disabled,
      ':hover': {
        opacity: theme.opacity.disabled,
      },
    };

  return {
    ...generalStyling,
    cursor: 'pointer',
    opacity: 1,
    outlineColor: `${theme.colors.interactiveFocused}`,
    '@media (hover: hover)': {
      ':hover': {
        opacity: theme.opacity.faded,
      },
    },
    ':focus-visible': {
      outlineWidth: '4px',
      outlineStyle: 'solid',
      outlineOffset: '2px',
    },
    ':active': {
      opacity: theme.opacity.faded,
    },
  };
};

const FelaClickable = styled('button', {
  displayName: 'ClickableC',
})(
  combineRules(boxRule, clickableRule),
  'color',
  'display',
  'height',
  'minHeight',
  'maxHeight',
  'width',
  'minWidth',
  'maxWidth',
  'overflow',
  'flex',
  'flexDirection',
  'flexWrap',
  'alignContent',
  'alignItems',
  'justifyContent',
  'justifyItems',
  'position',
  'kosmosSpacing',
  'kosmosBorderRadius',
  'kosmosGrid',
);

export const Clickable = React.forwardRef<HTMLElement, ClickableT>(
  (props, ref) => {
    const { disabled, href, onClick, target, as, className, ...rest } = props;
    const defaultClasses = [
      'r-box',
      'flex',
      'flex-col',
      'flex-none',
      'w-auto',
      'items-start',
      'min-w-0',
      'min-h-0',
      'relative',
      'rounded',
      'bg-transparent',
    ];

    if (href !== undefined) {
      return (
        <FelaClickable
          {...rest}
          ref={ref}
          href={disabled ? undefined : href}
          aria-disabled={disabled}
          onClick={disabled ? undefined : onClick}
          as={disabled ? 'span' : as ?? 'a'}
          role={disabled ? 'link' : undefined}
          target={disabled ? undefined : target}
          className={cn(defaultClasses, className)}
        />
      );
    }
    return (
      <FelaClickable
        tabIndex={!as || as === 'button' ? undefined : 0}
        {...rest}
        ref={ref}
        as={as ?? 'button'}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        className={cn(defaultClasses, className)}
      />
    );
  },
);

export type ClickableT = React.ComponentProps<typeof FelaClickable>;
