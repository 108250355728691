import { LocalStorage } from '@mentimeter/storage';
import { USER_LOCALE_STORAGE_KEY } from 'src/cookie-constants';
import Cookies from '@mentimeter/cookies';

/**
 * Sets the locale in local storage to show the correct locale during onboarding and redirect on content.
 */
export const setUserLocale = (locale: string) => {
  LocalStorage.setItem({
    type: 'functional',
    key: USER_LOCALE_STORAGE_KEY,
    value: locale,
  });

  Cookies.set({
    type: 'functional',
    name: USER_LOCALE_STORAGE_KEY,
    value: locale,
    options: {
      expires: 365,
      secure: true,
    },
  });
};

export const getUserLocale = () => {
  const cookieLocale = Cookies.get(USER_LOCALE_STORAGE_KEY);

  if (cookieLocale) return cookieLocale;

  return LocalStorage.getItem(USER_LOCALE_STORAGE_KEY);
};
