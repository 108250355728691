import { inferSourceFromReferrer } from './inferSourceFromReferrer';
import { LAST_TIMESTAMP_KEY, FIRST_TIMESTAMP_KEY } from './utmKeys';
import { getQueryParam } from './getQueryParam';
import { CAMPAIGN_KEYWORDS } from './constants';

export const campaignParams = (url: string, referrer: string) => {
  const { utm_source, utm_medium } = inferSourceFromReferrer(referrer);

  const initialUtms = {
    firstParams: {
      [`utm_source (first)`]: utm_source,
      ...(utm_medium && { [`utm_medium (first)`]: utm_medium }),
    },
    lastParams: {
      [`utm_source (last)`]: utm_source,
      ...(utm_medium && { [`utm_medium (last)`]: utm_medium }),
    },
  };

  const { firstParams, lastParams } = CAMPAIGN_KEYWORDS.reduce((acc, item) => {
    const paramValue = getQueryParam(url, item);
    if (!paramValue || ['null', 'undefined'].includes(paramValue)) {
      return acc;
    }

    return {
      firstParams: {
        ...acc.firstParams,
        [`${item} (first)`]: paramValue,
      },
      lastParams: {
        ...acc.lastParams,
        [`${item} (last)`]: paramValue,
      },
    };
  }, initialUtms);

  if (Object.keys(firstParams).length === 0) {
    return null;
  }

  const date = new Date().toISOString();

  return {
    lastParams: {
      ...lastParams,
      [LAST_TIMESTAMP_KEY]: date,
    },
    firstParams: {
      ...firstParams,
      [FIRST_TIMESTAMP_KEY]: date,
    },
  };
};
