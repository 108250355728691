import * as React from 'react';
import { Text, type TextT } from '../Text';

const ErrorMessage = ({
  color = 'negative',
  fontSize = '87.5',
  mt = 'space1',
  ...props
}: TextT) => <Text color={color} fontSize={fontSize} mt={mt} {...props} />;

export { ErrorMessage };
