import * as React from 'react';
import { useTransition, animated } from 'react-spring';
import { Portal } from '../Portal';
import { Placement, type PlacementProps } from '../Placement';
import { type ThemeMapTypeT, themeMap } from '../Themes';

export interface PopoverGenericT extends PlacementProps {
  referenceId: string;
  onDismiss?: undefined | ((event: MouseEvent) => void);
  onClickReference?: (event: MouseEvent) => void;
  show: boolean;
  delay?: number;
  autoFocus?: boolean;
  withPortal?: boolean | undefined;
  domId?: string | undefined;
  theme?: ThemeMapTypeT | undefined;
}

/**
 * @deprecated Use the new accessible popover components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-ui-popover-new--docs)
 */

const PopoverGeneric = ({
  show,
  onDismiss,
  onClickReference,
  referenceId,
  autoFocus,
  withPortal = true,
  domId = 'placement-portal',
  onDismiss: _ignore,
  delay,
  theme,
  ...rest
}: PopoverGenericT) => {
  const popover = React.useRef<HTMLDivElement | null>(null);
  const transition = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const detectOutsideOrReferenceClick = React.useCallback(
    (event: MouseEvent) => {
      if (!show || event.target === null) {
        return;
      }

      const target = event.target as Element;
      if (
        target.classList &&
        target.classList.contains(`${referenceId}-ignore`)
      ) {
        return;
      }

      const clickedOnPopover =
        popover.current && popover.current.contains(target);
      const reference = document.getElementById(referenceId);

      if (reference?.contains(target)) {
        onClickReference?.(event);
      } else if (!clickedOnPopover) {
        onDismiss?.(event);
      }
    },
    [onDismiss, show, referenceId, onClickReference],
  );

  React.useEffect(() => {
    if (show) {
      document.body.addEventListener(
        'mousedown',
        detectOutsideOrReferenceClick,
      );
    }
    return () => {
      document.body.removeEventListener(
        'mousedown',
        detectOutsideOrReferenceClick,
      );
    };
  }, [show, detectOutsideOrReferenceClick]);

  return (
    <>
      {transition((styles, show) => {
        const animatedDiv = (
          <animated.div
            aria-label="Popover"
            role="alert"
            aria-live="assertive"
            style={{
              ...styles,
              transitionDelay: delay ? `${delay}s` : '0s',
              zIndex: withPortal ? undefined : 1,
            }}
          >
            <div ref={popover}>
              <Placement
                {...rest}
                referenceId={referenceId}
                autoFocus={autoFocus}
              />
            </div>
          </animated.div>
        );

        if (!show) return null;

        if (theme) {
          const T = themeMap[theme];
          return withPortal ? (
            <Portal domId={domId} zIndex={9999}>
              <T> {animatedDiv}</T>
            </Portal>
          ) : (
            <T>{animatedDiv}</T>
          );
        }

        return withPortal ? (
          <Portal domId={domId} zIndex={9999}>
            {animatedDiv}
          </Portal>
        ) : (
          animatedDiv
        );
      })}
    </>
  );
};

export { PopoverGeneric };
