interface DevelopmentFlag {
  displayName: string;
  description: string;
  values: readonly string[];
}

/**
 * Development flags that can be toggled between different states for:
 * - continuously integrating hidden from users
 * - easily test complex states in the UI
 *
 * **When adding new flags:**
 *   please make sure the list is still sorted alphabetically.
 */
export const DEVELOPMENT_FLAGS = {
  'console-tracking': {
    displayName: 'Console tracking',
    description: 'Logs tracking events to the console.',
    values: ['on'],
  },
  'engagement-limit-progress': {
    displayName: 'Engagement limit progress',
    description:
      'Sets the progress of the engagement limit to specific thresholds that triggers different UI elements to be shown.',
    values: ['unengaged', 'activated-limit', 'nearing-limit', 'reached-limit'],
  },
  'home-mode': {
    displayName: 'Home mode',
    description: 'Forces the user home page to a specific state.',
    values: ['not-created', 'created', 'activated', 'loading'],
  },
  'template-helpers': {
    displayName: 'Template Helpers',
    description: 'Show extra helpers and info about templates.',
    values: ['on'],
  },
  'test-votes-indicator': {
    displayName: 'Test votes indicator',
    description: 'Shows a test votes indicator on the slide preview.',
    values: ['on'],
  },
  'notification-fixtures': {
    displayName: 'Notification fixtures',
    description: 'Toggle a predefined set of notifications for testing UI',
    values: ['loading-state', 'error-state', 'empty-state'],
  },
  'net30-condensed-flow': {
    displayName: 'Net30 condensed flow',
    description: 'Condenses the Net30 flow into two steps.',
    values: ['on'],
  },
} as const satisfies Record<string, DevelopmentFlag>;

export type DevelopmentFlagKey = keyof typeof DEVELOPMENT_FLAGS;
export type DevelopmentFlagValue<T extends DevelopmentFlagKey> =
  (typeof DEVELOPMENT_FLAGS)[T]['values'][number];
