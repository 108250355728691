// Polyfill to make Auth modal to work in IE
if (typeof window !== 'undefined') {
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      // @ts-expect-error-auto TS(2339) there's no msMatchesSelector in typescript documentation
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s: string) {
      let el: ParentNode | null = this;

      do {
        if (Element.prototype.matches.call(el, s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
}

export {};
