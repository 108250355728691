import React from 'react';
import { useTimeout } from '@mentimeter/react-hooks';
import { SplitContext } from '@splitsoftware/splitio-react';
import {
  type SplitEnvironmentContext,
  type SplitEnvironmentProviderProps,
} from './split-context';
import { useSplitOverrides } from './development-tools';
import { SPLITIO_TIMEOUT_MS } from './constants';
import type { ExperimentsSetup, GetExperimentsMap } from './types';

const IS_DEV = process.env.NODE_ENV === 'development';

/** Options for overriding default split behaviours. */
export interface SplitHookOptions {
  /** Custom attributes that you can target the user on when setting up the split. */
  splitAttributes?: Record<string, string | number | boolean>;
}

interface Options {
  getAttributes?: () =>
    | Record<string, string | number | boolean | null | undefined>
    | undefined;
}

export function createSplitEnvironment<Experiments extends ExperimentsSetup>(
  // Only used to help TypeScript infer types
  experiments: Experiments,
  options: Options = {},
  Context = React.createContext<SplitEnvironmentContext>({
    splitIO: null,
    experiments: {},
  }),
) {
  function createExperimentsMapWithTreatment(
    splitNames: (keyof Experiments)[],
    value: string,
  ) {
    return splitNames.reduce((acc, key) => {
      return { ...acc, [key]: value };
    }, {}) as GetExperimentsMap<Experiments>;
  }

  function Provider({
    forceControlTreatment = false,
    fallbackToControlAfterTimeout = false,
    children,
  }: React.PropsWithChildren<SplitEnvironmentProviderProps>) {
    const splitIO = React.useContext(SplitContext);

    const [hasTimedOut, setHasTimedOut] = React.useState(false);

    useTimeout(() => {
      // When an app fails to prepare for running tests it's
      // important that we fallback to control treatment.
      // this means if the flag shouldFallbackToNotReady is true
      // while this callback is fired there is no turning back
      // and all splits will return control during that session.
      if (fallbackToControlAfterTimeout) setHasTimedOut(true);
    }, SPLITIO_TIMEOUT_MS);

    return (
      <Context.Provider
        value={{
          splitIO,
          experiments,
          forceControlTreatment: forceControlTreatment || hasTimedOut,
          fallbackToControlAfterTimeout,
        }}
      >
        {children}
      </Context.Provider>
    );
  }

  function useSplitsLazy():
    | { isReady: false }
    | {
        isReady: true;

        /**
         * Get's the selected treatment for a set of split experiments that you pass in.
         * @param splitNames An array with the names of the split experiments you want to use.
         * @param options Options for overriding default split behaviours.
         */
        getSplits: (
          splitNames: (keyof Experiments)[],
          hookOptions?: SplitHookOptions,
        ) => GetExperimentsMap<Experiments>;
        destroy: () => Promise<void>;
      } {
    const overrides = useSplitOverrides();
    const {
      fallbackToControlAfterTimeout: shouldFallbackToNotReady,
      forceControlTreatment: shouldFallbackToControl,
      splitIO,
    } = React.useContext(Context);

    // We need to memo everything in this hook so that the getSplits function does
    // not change identity. Otherwise we might cause endless rerender loops when
    // the getSplits function is passed to the dependency array of another hook.
    return React.useMemo(() => {
      // If we are in development we want to return control treatment for all splits
      // since we don't even have a split client running.
      if (IS_DEV) {
        return {
          isReady: true,
          getSplits(splitNames) {
            const defaultTreatments = createExperimentsMapWithTreatment(
              splitNames,
              'control',
            );
            if (overrides) return overrides.fromTreatments(defaultTreatments);

            return defaultTreatments;
          },
          destroy: async () => {},
        };
      }

      // If we are in a state where we fail to boot/init the app we will fallback
      // to controlled state
      // E.g. if we want to run tests against a user in content-web but the user isn't
      // authenticated
      if (shouldFallbackToControl) {
        return {
          isReady: true,
          getSplits(splitNames) {
            return createExperimentsMapWithTreatment(splitNames, 'control');
          },
          destroy: splitIO?.client?.destroy ?? (async () => {}),
        };
      }

      // When app is booting up we have to possibility to explicity tell this hook
      // that we are note ready to run yet.
      // Examples could be if you
      // - server render and want to render a loading state
      // - intitializing the app to be ready to. eg. fetching data
      if (shouldFallbackToNotReady) {
        return { isReady: false };
      }

      if (splitIO?.isTimedout || !splitIO?.client) {
        return {
          isReady: true,
          getSplits(splitNames) {
            const defaultTreatments = createExperimentsMapWithTreatment(
              splitNames,
              'control',
            );

            if (overrides) return overrides.fromTreatments(defaultTreatments);

            return defaultTreatments;
          },
          destroy: splitIO?.client?.destroy ?? (async () => {}),
        };
      }

      const splitClient = splitIO.client;

      if (!splitIO?.isReady || !splitClient) {
        return { isReady: false };
      }

      return {
        isReady: true,
        getSplits(splitNames, hookOptions) {
          const featureFlags = splitNames as string[];
          const attributes = {
            ...options.getAttributes?.(),
            ...hookOptions?.splitAttributes,
            // devEnv: hookOptions?.devEnv ?? false,
          } as Record<string, string | number | boolean>;
          const realTreatments = splitClient.getTreatments(
            featureFlags,
            attributes,
          ) as GetExperimentsMap<Experiments>;

          if (overrides) return overrides.fromTreatments(realTreatments);

          return realTreatments;
        },
        destroy: splitClient.destroy,
      };
    }, [
      overrides,
      shouldFallbackToControl,
      shouldFallbackToNotReady,
      splitIO?.client,
      splitIO?.isReady,
      splitIO?.isTimedout,
    ]);
  }

  /**
   * Get's the selected treatment for a set of split experiments that you pass in.
   * @param splitNames An array with the names of the split experiments you want to use.
   * @param hookOptions Options for overriding default split behaviours.
   */
  function useSplits(
    splitNames: (keyof Experiments)[],
    hookOptions?: SplitHookOptions,
  ): { isReady: boolean } & GetExperimentsMap<Experiments> {
    // Use the useSplitsLazy hook to reuse its logic and keep maintenance low
    const lazyValue = useSplitsLazy();

    // Return 'not_ready' status for all splits until the SDK is ready
    if (!lazyValue.isReady)
      return {
        isReady: false,
        ...createExperimentsMapWithTreatment(splitNames, 'not_ready'),
      };

    return {
      isReady: true,
      ...lazyValue.getSplits(splitNames, hookOptions),
    };
  }

  return {
    useSplits,
    useSplitsLazy,
    SplitProvider: Provider,
  };
}
