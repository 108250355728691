/*
  The code in this file is used as a direct interface towards the user cache and session token in localStorage.
  There's a few direct uses of this class due to legacy handling, but generally speaking you should NOT use this code directly,
  but rather use the `useUser` hook inside React instead.
*/

import type { UserResponseT } from '@mentimeter/http-clients';
import axios from 'axios';
import {
  getAuthorizationBearer,
  getLocalUser,
  getSessionToken,
  removeLocalUser,
  removeLocalUserAndSessionToken,
  removeSessionToken,
  setLocalUser,
} from './storage';

interface UserCacheWrapper {
  getTrackingToken: () => string;
  removeToken: () => void;
  getToken: () => string;
  getAuthorizationValue: () => string;
  getUserCache: () => UserResponseT;
  toJSON: () => UserResponseT; // backwards compatibility
  deleteUserCache: () => void;
  setUserCache: (data: UserResponseT) => void;
  logout: (redirectPath: string) => Promise<void>;
  isAuthenticated: () => boolean;
}

const realClientSession: UserCacheWrapper = {
  getAuthorizationValue() {
    return getAuthorizationBearer();
  },
  // @ts-expect-error-auto TS(2322) FIXME: Type '() => string | null | undefined' is not assi... Remove this comment to see the full error message
  getToken() {
    return getSessionToken();
  },
  isAuthenticated() {
    return Boolean(getSessionToken());
  },
  removeToken() {
    removeSessionToken();
  },
  // @ts-expect-error-auto TS(2322) FIXME: Type '() => string | undefined' is not assignable ... Remove this comment to see the full error message
  getTrackingToken() {
    const user = getLocalUser();
    if (user) {
      return user.token;
    }
  },
  /**
   * @deprecated use useUser instead
   */
  toJSON() {
    return this.getUserCache();
  },
  // @ts-expect-error-auto TS(2322) FIXME: Type '() => { fromCache: true; active_workspace_id... Remove this comment to see the full error message
  getUserCache() {
    const userCache = getLocalUser();
    if (userCache) {
      return { ...userCache, fromCache: true };
    }
  },
  deleteUserCache() {
    removeLocalUser();
  },
  setUserCache(data) {
    setLocalUser(data, { setSessionToken: false });
  },

  async logout(redirectPath = '/') {
    try {
      const user = getLocalUser();
      if (user) {
        // This doesn't use http-client because it would cause a
        // circular dependency between this object and the client
        await axios.post(`${user.service_urls.core}/users/logout`, null, {
          headers: { Authorization: getAuthorizationBearer() },
        });
      }
    } catch {
      // If the logout request fails, we still want to remove the users token and redirect them
    }

    try {
      await removeLocalUserAndSessionToken();
    } catch {
      // If clearing token and cache fails, we still want to redirect the user
    }

    window.location.assign(redirectPath);
  },
};

export const userCache =
  typeof window === 'undefined'
    ? // @ts-expect-error-auto TS(2352) FIXME: Conversion of type 'undefined' to type 'UserCacheW... Remove this comment to see the full error message
      (undefined as UserCacheWrapper & UserResponseT)
    : ({
        ...realClientSession.getUserCache(),
        ...realClientSession,
      } as UserCacheWrapper & UserResponseT);
