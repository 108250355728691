import { getVisitorToken } from '@mentimeter/splitio';
import { MentiError, captureException } from '@mentimeter/errors';
import { tracking } from '@mentimeter/http-clients';
import type { TrackingPayload } from '@mentimeter/http-clients';

export const trackVisitor = async (payload: TrackingPayload) => {
  const visitorToken = getVisitorToken();
  if (visitorToken) {
    try {
      await tracking().trackVisitor(payload, visitorToken);
    } catch (error) {
      captureException(
        new MentiError('Visitor tracking exception', {
          cause: error,
          feature: 'acquisition-and-growth',
        }),
      );
    }
  }
};
