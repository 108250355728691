import { typographyScale } from '../typography-scale';

/**
 * Code ownership: Ragnar
 */

export interface RagnarTypography {
  ragnarHeadline1: string;
  ragnarHeadline2: string;
  ragnarHeadline3: string;
  ragnarBodyLg: string;
  ragnarBodyMd: string;
  ragnarBodySm: string;
  ragnarLabelMd: string;
  ragnarLabelSm: string;
  ragnarLabelXs: string;
  ragnarButtonLg: string;
  ragnarButtonMd: string;
  ragnarInputMd: string;
  ragnarInputSm: string;
}

export const ragnarTypography: RagnarTypography = {
  ragnarHeadline1: typographyScale.headlineLg,
  ragnarHeadline2: typographyScale.headlineSm,
  ragnarHeadline3: typographyScale.headline2Xs,
  ragnarBodyLg: typographyScale.bodyLg,
  ragnarBodyMd: typographyScale.bodyMd,
  ragnarBodySm: typographyScale.bodySm,
  ragnarLabelMd: typographyScale.labelMd,
  ragnarLabelSm: typographyScale.labelSm,
  ragnarLabelXs: typographyScale.labelXs,
  ragnarButtonLg: typographyScale.buttonLg,
  ragnarButtonMd: typographyScale.buttonMd,
  ragnarInputMd: typographyScale.inputMd,
  ragnarInputSm: typographyScale.inputSm,
};
