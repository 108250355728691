import * as React from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { type InputRuleStatus } from '../utils/inputRule';
import { variants } from './TextInputItem.variants';

type TextInputItemStatus = Exclude<
  InputRuleStatus,
  'disabled' | 'hover' | 'focused'
>;

export interface TextInputItemT
  extends React.ComponentProps<typeof FelaTextInputItem> {
  maxLength?: number | undefined;
  inputSize?: 'default' | 'compact' | undefined;
  status?: TextInputItemStatus | undefined;
}

const FelaTextInputItem = styled('input')('kosmosSpacing');

export const TextInputItem = React.forwardRef<HTMLInputElement, TextInputItemT>(
  (props, ref) => {
    const { className, ...rest } = props;

    const classes = variants({
      size: rest.inputSize,
      variant: rest?.status,
    });

    return (
      <FelaTextInputItem
        ref={ref}
        type="text"
        className={cn(classes, 'r-textinput', className)}
        {...rest}
      />
    );
  },
);
