import * as React from 'react';
import { animated, type SpringValues, useTransition } from 'react-spring';
import { useDelayedHover } from '@mentimeter/react-hooks';
import { Portal } from '../Portal';
import { Placement, type PlacementProps } from '../Placement';

export interface TooltipGenericT extends PlacementProps {
  referenceId: string;
  delay?: number;
  portalPosition?: React.ComponentProps<typeof Portal>['position'];
  withPortal?: boolean;
  domId?: string;
}

export const TooltipGeneric = ({
  referenceId,
  delay = 0,
  portalPosition,
  withPortal = true,
  domId = 'placement-portal',
  ...rest
}: TooltipGenericT) => {
  const isHovered = useDelayedHover(delay, referenceId);

  const transition = useTransition(isHovered, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {transition(
        (styles, show) =>
          show &&
          (withPortal ? (
            <Portal position={portalPosition} domId={domId} zIndex={9999}>
              <animated.div
                aria-live="assertive"
                style={styles as SpringValues}
              >
                <Placement referenceId={referenceId} {...rest} />
              </animated.div>
            </Portal>
          ) : (
            <animated.div aria-live="assertive" style={styles as SpringValues}>
              <Placement referenceId={referenceId} {...rest} />
            </animated.div>
          )),
      )}
    </>
  );
};
