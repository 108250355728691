import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { Title } from '@radix-ui/react-dialog';
import type { DialogTitleProps } from '@radix-ui/react-dialog';
import { addUnit } from '@mentimeter/ragnar-utils';

interface DialogTitlePrimitiveProps extends DialogTitleProps {
  size?: 'medium' | 'large' | undefined;
}

const dialogTitleRule: Rule<DialogTitlePrimitiveProps> = ({
  theme,
  size = 'medium',
}) => ({
  fontFamily: theme.fonts.heading,
  fontSize: addUnit(
    size === 'large' ? theme.kosmosFontSize[175] : theme.kosmosFontSize[125],
  ),
  lineHeight:
    size === 'large'
      ? theme.kosmosLineHeight.relative.snug
      : theme.kosmosLineHeight.relative.snug,
  fontWeight: theme.fontWeights.regular,
  color: theme.colors.text,
});
const DialogRadixTitleStyled = styled(
  Title,
  {
    displayName: 'DialogRadixTitleStyled',
  },
  true,
)(
  dialogTitleRule,
  'letterSpacing',
  'lineHeight',
  'textAlign',
  'color',
  'kosmosFontSize',
  'kosmosSpacing',
);

export const ModalTitle = DialogRadixTitleStyled;
