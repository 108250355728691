import { alpha } from './utils';

// Black Alpha
export const blackBase = '#000000';
export const blackAlpha50 = alpha(blackBase, 0.02);
export const blackAlpha100 = alpha(blackBase, 0.05);
export const blackAlpha200 = alpha(blackBase, 0.1);
export const blackAlpha300 = alpha(blackBase, 0.14);
export const blackAlpha400 = alpha(blackBase, 0.2);
export const blackAlpha500 = alpha(blackBase, 0.3);
export const blackAlpha600 = alpha(blackBase, 0.45);
export const blackAlpha700 = alpha(blackBase, 0.55);
export const blackAlpha800 = alpha(blackBase, 0.65);
export const blackAlpha900 = alpha(blackBase, 0.75);
export const blackAlpha1000 = alpha(blackBase, 0.85);

// White Alpha
export const whiteBase = '#ffffff';
export const whiteAlpha50 = alpha(whiteBase, 0.02);
export const whiteAlpha100 = alpha(whiteBase, 0.05);
export const whiteAlpha200 = alpha(whiteBase, 0.1);
export const whiteAlpha300 = alpha(whiteBase, 0.14);
export const whiteAlpha400 = alpha(whiteBase, 0.2);
export const whiteAlpha500 = alpha(whiteBase, 0.3);
export const whiteAlpha600 = alpha(whiteBase, 0.45);
export const whiteAlpha700 = alpha(whiteBase, 0.55);
export const whiteAlpha800 = alpha(whiteBase, 0.65);
export const whiteAlpha900 = alpha(whiteBase, 0.75);
export const whiteAlpha1000 = alpha(whiteBase, 0.85);

// Gray
export const grayBase = '#DBDCE1';
export const gray50 = '#FCFDFD';
export const gray100 = '#F4F4F6';
export const gray200 = '#EDEDF0';
export const gray300 = '#E8E8EB';
export const gray400 = '#E4E5E9';
export const gray500 = grayBase;
export const gray600 = '#BCBDC2';
export const gray700 = '#999A9D';
export const gray800 = '#6E6E71';
export const gray900 = '#58585A';
export const gray1000 = '#424244';
export const gray1100 = '#2C2C2D';
export const gray1200 = '#222222';
export const gray1300 = '#161617';
export const gray1400 = '#0F0F10';

// Blue Light
export const blueLightBase = '#B4CCE5';
export const blueLight50 = '#FAFBFD';
export const blueLight100 = '#F0F5FA';
export const blueLight200 = '#D9E5F2';
export const blueLight300 = '#CEDEEE';
export const blueLight400 = '#C7D9EB';
export const blueLight500 = blueLightBase;
export const blueLight600 = '#9BAFC5';
export const blueLight700 = '#7E8FA0';
export const blueLight800 = '#5A6673';

// Blue
export const blueBase = '#196CFF';
export const blue50 = '#EFF5FF';
export const blue100 = '#D1E2FF';
export const blue200 = '#8CB5FF';
export const blue300 = '#6A9FFF';
export const blue400 = '#5391FF';
export const blue500 = blueBase;
export const blue600 = '#165DDB';
export const blue700 = '#124CB2';
export const blue800 = '#0D3680';

// Blue Dark
export const blueDarkBase = '#101834';
export const blueDark50 = '#EEEFF1';
export const blueDark100 = '#CFD1D6';
export const blueDark200 = '#878B99';
export const blueDark300 = '#64697B';
export const blueDark400 = '#4C5267';
export const blueDark500 = blueDarkBase;
export const blueDark600 = '#0E152D';
export const blueDark700 = '#0B1124';
export const blueDark800 = '#080C1A';

// Blue Alpha
export const blueAlpha50 = alpha(blueBase, 0.25);
export const blueAlpha100 = alpha(blueBase, 0.35);
export const blueAlpha500 = alpha(blueBase, 0.5);
export const blueAlpha700 = alpha(blueBase, 0.75);
export const blueAlpha900 = alpha(blueBase, 0.9);

// Blue Dark Alpha
export const blueDarkAlpha50 = alpha(blueDarkBase, 0.25);
export const blueDarkAlpha100 = alpha(blueDarkBase, 0.35);
export const blueDarkAlpha500 = alpha(blueDarkBase, 0.5);
export const blueDarkAlpha700 = alpha(blueDarkBase, 0.75);
export const blueDarkAlpha900 = alpha(blueDarkBase, 0.9);

// Green Light
export const greenLightBase = '#B8FAD6';
export const greenLight50 = '#FAFFFC';
export const greenLight100 = '#F1FEF7';
export const greenLight200 = '#DBFDEB';
export const greenLight300 = '#D1FCE4';
export const greenLight400 = '#CAFBE0';
export const greenLight500 = greenLightBase;
export const greenLight600 = '#9ED7B8';
export const greenLight700 = '#81AF96';
export const greenLight800 = '#5C7D6B';

// Green
export const greenBase = '#00DB91';
export const green50 = '#EDFCF7';
export const green100 = '#CCF8E9';
export const green200 = '#80EDC8';
export const green300 = '#59E8B7';
export const green400 = '#40E4AD';
export const green500 = greenBase;
export const green600 = '#00BC7D';
export const green700 = '#009966';
export const green800 = '#006E49';

// Green Dark
export const greenDarkBase = '#0D6B45';
export const greenDark50 = '#EEF5F2';
export const greenDark100 = '#CFE1DA';
export const greenDark200 = '#86B5A2';
export const greenDark300 = '#629F86';
export const greenDark400 = '#4A9074';
export const greenDark500 = greenDarkBase;
export const greenDark600 = '#0B5C3B';
export const greenDark700 = '#094B30';
export const greenDark800 = '#073623';

// Pink Light
export const pinkLightBase = '#FFB9D0';
export const pinkLight50 = '#FFFAFC';
export const pinkLight100 = '#FFF1F6';
export const pinkLight200 = '#FFDCE7';
export const pinkLight300 = '#FFD1E0';
export const pinkLight400 = '#FFCBDC';
export const pinkLight500 = pinkLightBase;
export const pinkLight600 = '#DB9FB3';
export const pinkLight700 = '#B28192';
export const pinkLight800 = '#805D68';

// Pink
export const pinkBase = '#FF80AB';
export const pink50 = '#FFF6F9';
export const pink100 = '#FFE6EE';
export const pink200 = '#FFBFD5';
export const pink300 = '#FFACC8';
export const pink400 = '#FFA0C0';
export const pink500 = pinkBase;
export const pink600 = '#DB6E93';
export const pink700 = '#B25A78';
export const pink800 = '#804056';

// Pink Dark
export const pinkDarkBase = '#FF403D';
export const pinkDark50 = '#FFF2F1';
export const pinkDark100 = '#FFD9D8';
export const pinkDark200 = '#FF9F9E';
export const pinkDark300 = '#FF8381';
export const pinkDark400 = '#FF706E';
export const pinkDark500 = pinkDarkBase;
export const pinkDark600 = '#DB3734';
export const pinkDark700 = '#B22D2B';
export const pinkDark800 = '#80201F';

// Purple Light
export const purpleLightBase = '#D1D4FF';
export const purpleLight50 = '#FCFCFF';
export const purpleLight100 = '#F6F6FF';
export const purpleLight200 = '#E8E9FF';
export const purpleLight300 = '#E1E3FF';
export const purpleLight400 = '#DDDFFF';
export const purpleLight500 = purpleLightBase;
export const purpleLight600 = '#B4B6DB';
export const purpleLight700 = '#9294B2';
export const purpleLight800 = '#696A80';

// Purple
export const purpleBase = '#969CF5';
export const purple50 = '#F8F8FE';
export const purple100 = '#EAEBFD';
export const purple200 = '#CBCDFA';
export const purple300 = '#BBBFF8';
export const purple400 = '#B0B5F7';
export const purple500 = purpleBase;
export const purple600 = '#8186D3';
export const purple700 = '#696DAB';
export const purple800 = '#4B4E7B';

// Purple Dark
export const purpleDarkBase = '#6E54BD';
export const purpleDark50 = '#F5F3FA';
export const purpleDark100 = '#E2DDF2';
export const purpleDark200 = '#B6A9DE';
export const purpleDark300 = '#A190D4';
export const purpleDark400 = '#927FCD';
export const purpleDark500 = purpleDarkBase;
export const purpleDark600 = '#5F48A3';
export const purpleDark700 = '#4D3B84';
export const purpleDark800 = '#372A5F';

// Purple Light Alpha
export const purpleLightAlpha50 = alpha(purpleLightBase, 0.25);
export const purpleLightAlpha100 = alpha(purpleLightBase, 0.35);
export const purpleLightAlpha500 = alpha(purpleLightBase, 0.5);
export const purpleLightAlpha700 = alpha(purpleLightBase, 0.6);
export const purpleLightAlpha900 = alpha(purpleLightBase, 0.9);

// Yellow Light
export const yellowLightBase = '#FFE87A';
export const yellowLight50 = '#FFFDF6';
export const yellowLight100 = '#FFFAE4';
export const yellowLight200 = '#FFF3BC';
export const yellowLight300 = '#FFF0A9';
export const yellowLight400 = '#FFEE9B';
export const yellowLight500 = yellowLightBase;
export const yellowLight600 = '#DBC869';
export const yellowLight700 = '#B2A255';
export const yellowLight800 = '#80743D';

// Yellow
export const yellowBase = '#FFC738';
export const yellow50 = '#FFFBF1';
export const yellow100 = '#FFF4D7';
export const yellow200 = '#FFE39B';
export const yellow300 = '#FFDB7E';
export const yellow400 = '#FFD56A';
export const yellow500 = yellowBase;
export const yellow600 = '#DBAB30';
export const yellow700 = '#B28B27';
export const yellow800 = '#80641C';

// Yellow Dark
export const yellowDarkBase = '#CC660A';
export const yellowDark50 = '#FBF4EE';
export const yellowDark100 = '#F5E0CE';
export const yellowDark200 = '#E5B284';
export const yellowDark300 = '#DE9C60';
export const yellowDark400 = '#D98C47';
export const yellowDark500 = yellowDarkBase;
export const yellowDark600 = '#AF5809';
export const yellowDark700 = '#8F4707';
export const yellowDark800 = '#663305';

export const neutralAlpha50 = alpha(gray200, 0.25);
export const neutralAlpha100 = alpha(gray200, 0.4);
export const neutralAlpha500 = alpha(gray200, 0.5);
export const neutralAlpha700 = alpha(gray200, 0.6);
export const neutralAlpha900 = alpha(gray200, 0.95);
