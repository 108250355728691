import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { Overlay } from '@radix-ui/react-dialog';

const overlayShow = () => ({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const dialogOverlayRule: Rule = ({ theme }, renderer) => {
  return {
    backgroundColor: theme.colors.bgOverlay,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'fixed',
    zIndex: theme.zIndex.modal,
    '@media (prefers-reduced-motion: no-preference)': {
      animation: renderer
        ? `${renderer.renderKeyframe(overlayShow, {})} ${theme.durations[0]}s`
        : undefined,
    },
  };
};

const DialogRadixOverlayStyled = styled(
  Overlay,
  {
    displayName: 'DialogRadixOverlayStyled',
  },
  true,
)(dialogOverlayRule, 'color');

export const ModalOverlay = DialogRadixOverlayStyled;
