import React from 'react';
import Script from 'next/script';
import { createScriptCode } from './global-env-vars-internal';
import { getPublicEnvVars } from './getPublicEnvVars';

export default function PublicEnvVars() {
  if (typeof window === 'undefined') {
    return (
      <Script strategy="beforeInteractive">
        {createScriptCode(getPublicEnvVars())}
      </Script>
    );
  }
  return null;
}
