import React, { type JSX } from 'react';
import { addUnit } from '@mentimeter/ragnar-utils';
import { Box, type BoxT } from '../Box/Box';
import { ClickableIcon } from '../ClickableIcon';

interface TextInputIcon extends BoxT {
  icon: JSX.Element;
  iconLabel?: string | undefined;
  iconAction?: (() => void) | undefined;
}

export const TextInputIcon = ({
  icon,
  iconLabel = undefined,
  iconAction = undefined,
  ...rest
}: TextInputIcon) => {
  if (!icon) return;

  return (
    <Box
      position="absolute"
      zIndex={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      color="text"
      aria-hidden={!iconAction}
      extend={() => ({
        pointerEvents: iconAction ? 'auto' : 'none',
      })}
      {...rest}
    >
      {iconAction ? (
        <ClickableIcon
          alignItems="center"
          justifyContent="center"
          aria-label={iconLabel}
          onClick={iconAction}
          extend={({ theme }) => ({
            width: addUnit(theme.kosmosSpacing.space4),
            height: addUnit(theme.kosmosSpacing.space4),
          })}
        >
          {icon}
        </ClickableIcon>
      ) : (
        icon
      )}
    </Box>
  );
};
