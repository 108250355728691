import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import type { Colors, ColorsEnum, DSC } from '@mentimeter/ragnar-dsc';
import { forwardRef } from 'react';

interface PlacementWrapT {
  ignorePointer: boolean;
  bg: ColorsEnum;
}

const ARROW_SIZE = 10;

const ARROW = {
  width: `${ARROW_SIZE}px`,
  height: `${ARROW_SIZE}px`,
};

const isThemeColor = (theme: DSC, c: ColorsEnum): c is keyof Colors =>
  Object.keys(theme.colors).includes(c);

const rule: Rule<PlacementWrapT> = ({
  theme,
  ignorePointer,
  bg = 'highContrast',
}) => ({
  pointerEvents: ignorePointer ? 'none' : undefined,
  '> .popper--arrow': {
    ...ARROW,
  },
  '> .popper--arrow:before': {
    position: 'absolute',
    content: "''",
    borderRadius: '2px',
    transform: 'rotate(45deg)',
    background: isThemeColor(theme, bg) ? theme.colors[bg] : undefined,
    ...ARROW,
  },
  '[data-popper-placement^="top"] .popper--arrow': {
    bottom: `-${ARROW_SIZE / 2 - 2}px`,
  },
  '[data-popper-placement^="bottom"] .popper--arrow': {
    top: `-${ARROW_SIZE / 2 - 2}px`,
  },
  '[data-popper-placement^="right"] .popper--arrow': {
    left: `-${ARROW_SIZE / 2 - 2}px`,
  },
  '[data-popper-placement^="left"] .popper--arrow': {
    right: `-${ARROW_SIZE / 2 - 2}px`,
  },
});

const PlacementWrapStyled = styled('div', {
  displayName: 'PlacementWrap',
})(rule);

type PlacementWrapProps = React.ComponentProps<typeof PlacementWrapStyled>;

export const PlacementWrap = forwardRef<
  HTMLDivElement | null,
  PlacementWrapProps
>((props, ref) => <PlacementWrapStyled tabIndex={-1} {...props} ref={ref} />);
