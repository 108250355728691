import Cookies, { cookiesAreEnabled } from '@mentimeter/cookies';
import { LAST_TOUCH_KEY, FIRST_TOUCH_KEY } from './utmKeys';
import { campaignParams } from './campaignParams';
import { COOKIE_EXPIRATION_DAYS } from './constants';

export const parseAndStoreUtmParams = (url: string): void => {
  if (!cookiesAreEnabled()) return;
  const utmParams = campaignParams(url, document?.referrer);
  if (!utmParams) return;

  Cookies.setJSON({
    type: 'performance',
    name: LAST_TOUCH_KEY,
    value: utmParams.lastParams,
    options: {
      expires: COOKIE_EXPIRATION_DAYS,
    },
  });

  if (!Cookies.get(FIRST_TOUCH_KEY)) {
    Cookies.setJSON({
      type: 'performance',
      name: FIRST_TOUCH_KEY,
      value: utmParams.firstParams,
      options: {
        expires: COOKIE_EXPIRATION_DAYS,
      },
    });
  }
};
