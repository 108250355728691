// http://www.primaryobjects.com/2012/11/19/parsing-hostname-and-domain-from-a-url-with-javascript/
const getHostNameFromUrl = (url: string) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (
    match !== null &&
    match.length > 2 &&
    typeof match[2] === 'string' &&
    match[2].length > 0
  ) {
    const hostname = match[2].split('.');
    return hostname[0];
  } else {
    return null;
  }
};

const searchEngines = [
  'google',
  'bing',
  'yahoo',
  'ecosia',
  'naver',
  'duckduckgo',
  'yandex',
  'baidu',
  'daum',
  'qwant',
];

export const inferSourceFromReferrer = (referrer: string) => {
  let utm_source = 'direct';
  let utm_medium;
  if (referrer) {
    const hostname = getHostNameFromUrl(referrer);
    const searchEngine = searchEngines.find((engine) => engine === hostname);
    if (searchEngine) {
      utm_source = searchEngine;
      utm_medium = 'organic';
    } else {
      const fullUrl = new URL(referrer);
      utm_source = fullUrl.hostname.replace('www.', '');
      utm_medium = 'referral';
    }
  }
  return {
    utm_source,
    utm_medium,
  };
};
