export const getQueryParam = (url: string, param: string): string => {
  // Expects a raw URL
  const paramFixed = param.replace(/[[]/, '[').replace(/[]]/, ']');
  const regexS = `[\?&]${paramFixed}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (results && typeof results[1] === 'string' && results[1].length) {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }
  return '';
};
