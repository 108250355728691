import * as React from 'react';
import type { BoxT } from '../Box';
import { CharacterCount, CharacterCountWrap } from '../CharacterCount';

interface PropsT extends BoxT {
  maxLength: number;
  value: string;
  render: (arg0: {
    value: string;
    onFocus: React.FocusEventHandler;
    onBlur: React.FocusEventHandler;
  }) => React.ReactNode;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  multiline?: boolean;
  iconTrailing?: boolean;
}

interface StateT {
  inFocus: boolean;
}

class FocusableCharacterCount extends React.Component<PropsT, StateT> {
  static defaultProps: Partial<PropsT> = {
    width: '100%',
    flex: 1,
  };
  override state = {
    inFocus: false,
  };

  handleFocus = (e: React.FocusEvent) => {
    const { onFocus } = this.props;
    this.setState({
      inFocus: true,
    });
    if (onFocus) onFocus(e);
  };
  handleBlur = (e: React.FocusEvent) => {
    const { onBlur } = this.props;
    this.setState({
      inFocus: false,
    });
    if (onBlur) onBlur(e);
  };

  override render() {
    const {
      id,
      render,
      maxLength,
      value,
      multiline,
      onFocus: _of,
      onBlur: _ob,
      iconTrailing,
      ...rest
    } = this.props;
    const { inFocus } = this.state;

    return (
      <CharacterCountWrap multiline={multiline} {...rest}>
        {render({
          onFocus: this.handleFocus,
          onBlur: this.handleBlur,
          value,
        })}
        {inFocus && Boolean(maxLength) && (
          <CharacterCount
            maxLength={maxLength}
            value={value}
            multiline={multiline}
            iconTrailing={iconTrailing}
            id={id}
          />
        )}
      </CharacterCountWrap>
    );
  }
}

export default FocusableCharacterCount;
