import useSWR from 'swr';
import { core, isAxiosError } from '@mentimeter/http-clients';
import { to } from '@mentimeter/__app-split-lib/utils';
import type { AxiosErrorToJSON } from '@mentimeter/http-clients';

export const useSubscriptions = (skip = false) => {
  const {
    data: subscriptions,
    error,
    isValidating,
  } = useSWR(
    !skip ? '/subscriptions' : null,
    async () => {
      const [fetchError, response] = await to(core().subscriptions.get());

      if (isAxiosError(fetchError)) throw fetchError.toJSON();

      return response?.data;
    },
    { revalidateOnFocus: false },
  );

  return {
    isLoading: !subscriptions && !error,
    isValidating,
    subscriptions,
    error: error as AxiosErrorToJSON,
  };
};
