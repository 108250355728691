import { DEFAULT_LOCALE, LOCALES } from '../constants.cjs';

export function fetchLocaleFromPathname(pathname: string) {
  const locale = pathname.split('/')[1];

  if (!locale || !LOCALES.includes(locale)) return DEFAULT_LOCALE;

  return locale;
}

export function getRouterLocale() {
  if (typeof window === 'undefined') return DEFAULT_LOCALE;

  return fetchLocaleFromPathname(window.location.pathname);
}

export function getPathnameWithoutLocale(pathname: string) {
  const existingLocale = fetchLocaleFromPathname(pathname); // one of locales, including default

  if (!LOCALES.includes(existingLocale) || existingLocale === DEFAULT_LOCALE) {
    // there's no locale
    return pathname;
  }

  return '/' + pathname.split('/').slice(2).join('/');
}

export function switchLocale(pathname: string, newLocale: string) {
  return '/' + newLocale + getPathnameWithoutLocale(pathname);
}

export function getFullURL(pathname: string, searchParams: string | undefined) {
  if (!searchParams) return pathname;

  if (pathname === '/') return '?' + searchParams;

  return pathname + '?' + searchParams;
}
