import * as React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { Box } from '../Box/Box';
import type { BoxT } from '../Box/Box';
import { Clickable } from '../Clickable';
import { ModalTitle } from './ModalTitle';
import { ModalDismiss } from './ModalDismiss';

interface ModalHeaderProps extends BoxT {
  size?: 'medium' | 'large';
  dismissable?: boolean;
  dismissLabel?: string;
  onDismissClick?: () => void;
  children?: React.ReactNode;
}

export const ModalHeader = ({
  size,
  dismissable = true,
  dismissLabel,
  onDismissClick,
  extend: propsExtend,
  children,
  ...boxProps
}: ModalHeaderProps) => {
  const closeLabel = dismissLabel ? dismissLabel : `Close dialog`;

  const extend: Extend = ({ theme }) => ({
    paddingTop: `${
      theme.kosmosSpacing.space8 +
      (dismissable ? theme.kosmosSpacing.space4 : 0)
    }px`,
    ...(propsExtend ? propsExtend({ theme }) : {}),
  });
  return (
    <Box width="100%" px="space8" pb="space1" extend={extend} {...boxProps}>
      <ModalTitle size={size}>{children}</ModalTitle>
      {dismissable && (
        <ModalDismiss>
          <Clickable
            aria-label={closeLabel}
            position="absolute"
            top={0}
            right={0}
            onClick={onDismissClick}
            p="space4"
          >
            <CrossIcon size={3} />
          </Clickable>
        </ModalDismiss>
      )}
    </Box>
  );
};
