import { typographyScale } from '../typography-scale';

/**
 * Code ownership: Insights
 */

export interface ResultsTypography {
  resultsHeadline1: string;
  resultsHeadline2: string;
  resultsHeadline3: string;
  resultsHeadline4: string;
  resultsHeadline5: string;
  resultsBodyLg: string;
  resultsBodyMd: string;
  resultsBodySm: string;
  resultsBodyXs: string;
  resultsLabelMd: string;
  resultsLabelSm: string;
  resultsLabelXs: string;
  resultsNumberLg: string;
  resultsNumberMd: string;
  resultsNumberSm: string;
}

export const resultsTypography: ResultsTypography = {
  resultsHeadline1: typographyScale.headline3Xl,
  resultsHeadline2: typographyScale.headline2Xl,
  resultsHeadline3: typographyScale.headlineLg,
  resultsHeadline4: typographyScale.headlineSm,
  resultsHeadline5: typographyScale.headline2Xl,
  resultsBodyLg: 'text-112.5 leading-normal font-normal font-body',
  resultsBodyMd: typographyScale.bodyMd,
  resultsBodySm: typographyScale.bodySm,
  resultsBodyXs: typographyScale.bodyXs,
  resultsLabelMd: typographyScale.labelMd,
  resultsLabelSm: typographyScale.labelSm,
  resultsLabelXs: typographyScale.labelXs,
  resultsNumberLg: 'text-112.5 leading-relaxed font-normal font-body',
  resultsNumberMd: 'text-87.5 leading-relaxed font-normal font-body',
  resultsNumberSm: 'text-75 leading-relaxed font-normal font-body',
};
