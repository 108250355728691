import * as React from 'react';
import {
  AccessibilityIcon,
  EyeIcon,
  type IconT,
} from '@mentimeter/ragnar-visuals';
import {
  AdminIcon,
  AlertIcon,
  PlaySolidIcon,
  StarSolidIcon,
} from '@mentimeter/ragnar-visuals';
import { Box } from '../Box/Box';
import type { BadgeRibbonT } from '../Ribbon/badgeRibbonTypes';

export const BADGE_MAP: {
  [key in BadgeRibbonT]: {
    title: React.FC<IconT> | string;
    bg: string;
    color: string;
    description?: string;
  };
} = {
  basic: {
    title: 'Basic',
    bg: 'positiveWeak',
    color: 'onPositiveWeak',
  },
  pro: {
    title: 'Pro',
    bg: 'positiveWeak',
    color: 'onPositiveWeak',
  },
  'pro-multi': {
    title: 'Only for teams',
    bg: 'neutralWeak',
    color: 'onNeutralWeak',
  },
  enterprise: {
    title: 'Enterprise',
    bg: 'positiveWeak',
    color: 'onPositiveWeak',
  },
  new: {
    title: 'New',
    bg: 'noticeWeak',
    color: 'onNoticeWeak',
  },
  beta: {
    title: 'Beta',
    bg: 'infoWeak',
    color: 'onInfoWeak',
  },
  upgrade: {
    title: (props: IconT) => <StarSolidIcon {...props} />,
    bg: 'positiveWeak',
    color: 'onPositiveWeak',
  },
  shared: {
    title: 'Shared',
    bg: 'infoWeak',
    color: 'onInfoWeak',
    description: 'Shared as a template with your organization',
  },
  template: {
    title: 'Template',
    bg: 'infoWeak',
    color: 'onInfoWeak',
    description: 'Shared as a template with your organization',
  },
  example: {
    title: 'Example',
    bg: 'neutralWeak',
    color: 'onNeutralWeak',
    description: 'Made from an example presentation',
  },
  team: {
    title: 'Team',
    bg: 'infoWeak',
    color: 'onInfoWeak',
  },
  admin: {
    title: (props) => <AdminIcon aria-label="Admin icon" {...props} />,
    bg: 'noticeWeak',
    color: 'onNoticeWeak',
  },
  soon: {
    title: 'Coming Soon',
    bg: 'infoWeak',
    color: 'onInfoWeak',
  },
  text: {
    title: '',
    bg: 'infoWeak',
    color: 'onInfoWeak',
  },
  'view-only': {
    title: 'View only',
    bg: 'neutralWeak',
    color: 'onNeutralWeak',
  },
  'view-only-icon': {
    title: (props) => <EyeIcon aria-label="view only" {...props} size={1} />,
    bg: 'neutralWeak',
    color: 'onNeutralWeak',
  },
  archived: {
    title: 'Archived',
    bg: 'neutralWeak',
    color: 'onNeutralWeak',
  },
  '50-licenses': {
    title: '+50 licenses',
    bg: 'neutralWeak',
    color: 'onNeutralWeak',
  },
  warning: {
    title: (props) => <AlertIcon aria-label="Alert icon" {...props} size={1} />,
    bg: 'noticeWeak',
    color: 'onNoticeWeak',
  },
  play: {
    title: (props) => (
      <Box extend={() => ({ transform: 'scale(0.8) translateX(1px)' })}>
        <PlaySolidIcon aria-label="Play icon" {...props} />
      </Box>
    ),
    bg: 'highContrast',
    color: 'onHighContrast',
  },
  'published template': {
    title: 'Published template',
    bg: 'infoWeak',
    color: 'onInfoWeak',
  },
  premium: {
    title: 'Premium',
    bg: 'positiveWeak',
    color: 'onPositiveWeak',
  },
  free: {
    title: 'Free',
    bg: 'noticeWeak',
    color: 'onNoticeWeak',
  },
  accessibility: {
    title: (props) => <AccessibilityIcon {...props} size={1} />,
    bg: 'noticeWeak',
    color: 'onNoticeWeak',
  },
};
