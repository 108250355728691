import {
  blackAlpha700,
  blue100,
  blue50,
  blue500,
  blue400,
  blueDark500,
  gray400,
  gray500,
  gray600,
  green100,
  green500,
  greenDark800,
  pinkDark50,
  pinkDark100,
  pinkDark600,
  pinkDark800,
  purple50,
  purpleDark500,
  purpleDark800,
  whiteBase,
  yellow100,
  yellow500,
  blue200,
  blue800,
  gray1050,
  gray1100,
  gray1300,
  pinkDark200,
  purpleDark100,
  yellowDark800,
  yellowLight50,
  pinkDark700,
  blackBase,
  gray1400,
  gray1200,
  gray1000,
  gray700,
  gray900,
  purpleLight100,
  yellowDark700,
  pinkDark400,
  greenLight50,
  yellowLight100,
  gray800,
  blue300,
  green300,
  green400,
  pinkDark500,
  purpleLightAlpha100,
  purpleDark300,
  purpleLight600,
  neutralAlpha700,
  neutralAlpha900,
  purpleDark400,
  purpleDark200,
  gray100,
} from '../darkPalette';
import type { Colors } from '../colors.types';
import { whiteAlpha300 } from '../palette';

/** IMPORTANT! Any changes to dark needs to be reflected in tailwind!
 * 1. Apply the same changes to the respective theme in colorTokenToPaletteMap.ts, in playground-web
 * 2. Run localhost:1000/tailwind (playground-web) with the theme settings and use "console.log(colorTokenMap)"
 * 3. Update the dark theme class framework colors in globals.css
 */
export const dark: Colors = {
  bg: gray1300,
  bgStrong: gray1400,
  bgOverlay: blackAlpha700,
  surface: gray1200,
  surfaceHover: gray1050,
  surfaceActive: gray1200,
  surfaceChecked: purple50,
  surfaceCheckedHover: purpleDark100,
  surfaceRaised: gray1100,
  surfaceSunken: gray1400,
  surfaceOverlay: gray1000,
  surfaceOverlayTransparent: neutralAlpha700,
  surfaceOverlayTransparentHover: neutralAlpha900,
  highContrast: blackBase,
  border: gray1100,
  borderWeak: gray1000,
  borderWeaker: gray1100,
  borderStrong: gray700,
  borderBrandWeakest: blue100,
  borderPrimaryWeakest: blue100,
  borderNegativeWeakest: pinkDark100,
  borderNeutralWeakest: gray900,
  borderInfoWeakest: purpleLight100,
  borderPositiveWeakest: green100,
  borderNoticeWeakest: yellow100,
  borderSecondaryWeakest: purpleDark100,
  borderDisabled: gray1100,
  borderTransparent: whiteAlpha300,
  brand: blue500,
  brandWeak: blue200,
  brandWeakest: blue50,
  text: gray500,
  textLink: whiteBase,
  textWeak: gray700,
  textWeaker: gray600,
  textNegative: pinkDark700,
  textPrimary: whiteBase,
  textDisabled: gray900,
  onBrand: whiteBase,
  onBrandWeak: blue800,
  onBrandWeakest: blue800,
  onDisabled: gray700,
  onDisabledStrong: gray400,
  onInfo: whiteBase,
  onInfoWeak: purpleDark800,
  onInfoWeakest: purpleDark800,
  onInteractive: whiteBase,
  onHighContrast: gray100,
  onNeutral: whiteBase,
  onNeutralWeak: whiteBase,
  onNeutralWeakest: whiteBase,
  onNegative: whiteBase,
  onNegativeWeak: pinkDark800,
  onNegativeWeakest: pinkDark800,
  onNotice: blueDark500,
  onNoticeWeak: yellowDark700,
  onNoticeWeakest: yellowDark800,
  onPositive: blueDark500,
  onPositiveWeak: greenDark800,
  onPositiveWeakest: greenDark800,
  onPrimary: gray1300,
  onPrimaryWeak: whiteBase,
  onPrimaryWeakest: whiteBase,
  onSecondary: whiteBase,
  onSecondaryWeak: purpleLight600,
  onSecondaryWeakest: purpleLight600,
  primary: gray100,
  primaryWeak: gray1000,
  primaryWeakest: gray1100,
  info: purpleDark500,
  infoWeak: purpleLight100,
  infoWeakest: purpleLight100,
  neutral: gray1000,
  neutralWeak: gray1100,
  neutralWeakest: gray1100,
  negative: pinkDark400,
  negativeWeak: pinkDark200,
  negativeWeakest: pinkDark50,
  positive: green500,
  positiveWeak: green100,
  positiveWeakest: greenLight50,
  secondary: purpleDark300,
  secondaryWeak: purpleDark100,
  secondaryWeakest: purple50,
  notice: yellow500,
  noticeWeak: yellowLight100,
  noticeWeakest: yellowLight50,
  disabled: gray1000,
  disabledStrong: gray800,
  interactive: blue500,
  interactiveFocused: purpleLightAlpha100,
  // COMPONENT TOKENS
  buttonBrandBg: blue500,
  buttonBrandBgHover: blue400,
  buttonBrandBgActive: blue300,
  buttonBrandWeakBg: blue50,
  buttonBrandWeakBgHover: blue100,
  buttonBrandWeakBgActive: blue200,
  buttonNegativeBg: pinkDark600,
  buttonNegativeBgHover: pinkDark500,
  buttonNegativeBgActive: pinkDark400,
  buttonNegativeWeakBg: pinkDark50,
  buttonNegativeWeakBgHover: pinkDark100,
  buttonNegativeWeakBgActive: pinkDark200,
  buttonOutlineBgHover: gray1100,
  buttonOutlineBgActive: gray1000,
  buttonOutlineBgChecked: purpleDark100,
  buttonOutlineBgCheckedHover: purpleDark200,
  buttonOutlineBgCheckedActive: purpleDark300,
  buttonPositiveBg: green500,
  buttonPositiveBgHover: green400,
  buttonPositiveBgActive: green300,

  buttonPrimaryBg: gray500,
  buttonPrimaryBgHover: gray600,
  buttonPrimaryBgActive: gray800,
  buttonPrimaryBgChecked: purpleDark300,
  buttonPrimaryBgCheckedHover: purpleDark500,
  buttonPrimaryBgCheckedActive: purpleDark400,

  buttonSecondaryBgHover: gray1100,
  buttonSecondaryBgActive: gray1000,
  buttonSecondaryBgChecked: purpleDark100,
  buttonSecondaryBgCheckedHover: purpleDark200,
  buttonSecondaryBgCheckedActive: purpleDark300,
  buttonTertiaryBg: gray1100,
  buttonTertiaryBgHover: gray1200,
  buttonTertiaryBgActive: gray1300,
  buttonTertiaryBgChecked: purpleDark300,
  buttonTertiaryBgCheckedHover: purpleDark500,
  buttonTertiaryBgCheckedActive: purpleDark400,
  buttonPrimaryReverseBg: gray1200,
  buttonPrimaryReverseBgHover: gray1300,
  buttonPrimaryReverseBgActive: gray1400,
  buttonPrimaryReverseBgChecked: purpleDark300,
  buttonPrimaryReverseBgCheckedHover: purpleDark500,
  buttonPrimaryReverseBgCheckedActive: purpleDark400,
  inputBg: gray1100,
  inputBgActive: gray1200,
};
