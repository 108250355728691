import { cva, cn } from '@mentimeter/ragnar-tailwind-config';

const classes = cva(
  [
    'box-border',
    'block',
    'w-full',
    'appearance-none',
    'font-body',
    'cursor-auto',
    'focus:text',
    'focus:bg',
    'focus-visible:outline',
    'focus-visible:outline-offset-2',
    'focus-visible:outline-4',
    'focus-visible:outline-interactive-focused',
    'disabled:bg-disabled-strong',
    'disabled:text-on-disabled-strong',
    'disabled:placeholder:text-on-disabled-strong',
    'disabled:cursor-not-allowed',
    'placeholder:text-weaker',
  ],
  {
    variants: {
      variant: {
        idle: [
          'bg-input',
          'text',
          'enabled:shadow-[inset_0_0_0_2px_theme(borderColor.weaker)]',
          'hover:enabled:shadow-[inset_0_0_0_2px_theme(colors.secondary.DEFAULT)]',
          'focus:enabled:shadow-[inset_0_0_0_2px_theme(colors.secondary.DEFAULT)]',
        ],
        error: [
          'bg-negative-weakest',
          'hover:enabled:bg',
          'text-on-negative-weakest',
          'enabled:shadow-[inset_0_0_0_2px_theme(colors.negative.DEFAULT)]',
        ],
        success: [
          'bg-positive-weakest',
          'hover:enabled:bg',
          'text-on-positive-weakest',
          'enabled:shadow-[inset_0_0_0_2px_theme(colors.positive.DEFAULT)]',
        ],
      },
      padding: {
        compact: ['py-3', 'px-2'],
        default: ['p-4'],
      },
      text: {
        compact: ['text-87.5', 'leading-100'],
        default: ['text-100', 'leading-125'],
      },
      borderRadius: {
        compact: ['rounded-lg'],
        default: ['rounded-xl'],
      },
    },
    defaultVariants: {
      variant: 'idle',
      padding: 'default',
      text: 'default',
      borderRadius: 'default',
    },
  },
);

interface variants {
  size?: 'compact' | 'default' | undefined;
  variant?: 'idle' | 'error' | 'success' | undefined;
}

export const variants = ({ size, variant }: variants) => {
  return cn(
    classes({
      variant,
      padding: size,
      text: size,
      borderRadius: size,
    }),
  );
};
