import React from 'react';
import type { BoxT } from '../Box/Box';
import { Box } from '../Box/Box';

export const ModalBody = React.forwardRef<HTMLDivElement, BoxT>(
  (props, ref) => (
    <Box
      ref={ref}
      width="100%"
      flex={1}
      overflow="auto"
      px="space8"
      pt="space4"
      pb="space8"
      {...props}
    />
  ),
);
