import { px } from '@mentimeter/ragnar-utils';
import { alpha } from '@mentimeter/ragnar-colors';
import type { Rule } from '@mentimeter/ragnar-styled';
import type { CSSProperties } from 'react';
import type { DSC } from '@mentimeter/ragnar-dsc';

export type InputRuleStatus =
  | 'idle'
  | 'error'
  | 'success'
  | 'disabled'
  | 'hover'
  | 'focused';
export interface InputRuleT {
  maxLength?: number | undefined;
  status?: InputRuleStatus | undefined;
}

const resolveVariant = (theme: DSC, status: InputRuleStatus) => {
  const variants: Record<InputRuleStatus, CSSProperties> = {
    idle: {
      backgroundColor: theme.colors.inputBg,
      color: theme.colors.text,
      boxShadow: `inset 0 0 0 2px ${theme.colors.borderWeaker}`,
    },
    hover: {
      backgroundColor: theme.colors.inputBg,
      boxShadow: `inset 0 0 0 2px ${theme.colors.secondary}`,
    },
    focused: {
      backgroundColor: theme.colors.bg,
      color: theme.colors.text,
      boxShadow: `inset 0 0 0 2px ${theme.colors.secondary}`,
      outline: `4px solid ${theme.colors.interactiveFocused}`,
    },
    error: {
      backgroundColor: theme.colors.negativeWeakest,
      color: theme.colors.onNegativeWeakest,
      boxShadow: `inset 0 0 0 2px ${theme.colors.negative}`,
    },
    success: {
      backgroundColor: theme.colors.positiveWeakest,
      color: theme.colors.onPositiveWeakest,
      boxShadow: `inset 0 0 0 2px ${theme.colors.positive}`,
    },
    disabled: {
      backgroundColor: theme.colors.disabledStrong,
      color: theme.colors.onDisabledStrong,
      boxShadow: 'none',
    },
  };
  return variants[status];
};

export const resolveInputFocusStyle = (
  status: InputRuleStatus,
  theme: DSC,
): CSSProperties => {
  const variant = resolveVariant(theme, 'focused' as InputRuleStatus);

  switch (status) {
    case 'error':
      return {
        outline: `4px solid ${alpha(theme.colors.negative, 0.2)}`,
        color: theme.colors.text,
      };
    case 'success':
      return {
        outline: `4px solid ${alpha(theme.colors.positive, 0.2)}`,
        color: theme.colors.text,
      };
    default:
      return { ...variant };
  }
};

export const resolveInputHoverStyle = (
  status: InputRuleStatus,
  theme: DSC,
): CSSProperties => {
  const variant = resolveVariant(theme, 'hover' as InputRuleStatus);

  switch (status) {
    case 'error':
    case 'success':
      return {
        backgroundColor: theme.colors.bg,
        color: theme.colors.text,
      };
    default:
      return { ...variant };
  }
};

export const inputRule: Rule<InputRuleT> = ({ theme, status = 'idle' }) => {
  const variant = resolveVariant(theme, status);

  return {
    display: 'block',
    width: '100%',
    appearance: 'none',
    webkitAppearance: 'none',
    boxSizing: 'border-box',
    fontFamily: theme.fonts.body,
    borderRadius: px(theme.kosmosBorderRadius.xl),
    cursor: 'auto',
    transition: `padding ${theme.durations[1]}s ease-in-out,`,
    outlineOffset: '2px',
    ...variant,
    ':hover:enabled': {
      ...resolveInputHoverStyle(status, theme),
    },
    ':focus': {
      ...resolveInputFocusStyle(status, theme),
      backgroundColor: theme.colors.bg,
    },
    ':focus:hover': {
      ...resolveInputFocusStyle(status, theme),
      backgroundColor: theme.colors.bg,
    },
    ':disabled, :hover:disabled': {
      ...resolveVariant(theme, 'disabled'),
      cursor: 'not-allowed',
    },
    '::-webkit-input-placeholder': {
      color: theme.colors.textWeaker,
      '&:disabled': {
        color: theme.colors.onDisabledStrong,
      },
    },
    '::-moz-placeholder': {
      color: theme.colors.textWeaker,
      '&:disabled': {
        color: theme.colors.onDisabledStrong,
      },
    },
    ':-ms-input-placeholder': {
      color: theme.colors.textWeaker,
      '&:disabled': {
        color: theme.colors.onDisabledStrong,
      },
    },
    ':-moz-placeholder': {
      color: theme.colors.textWeaker,
      '&:disabled': {
        color: theme.colors.onDisabledStrong,
      },
    },
    '::placeholder': {
      color: theme.colors.textWeaker,
      '&:disabled': {
        color: theme.colors.onDisabledStrong,
      },
    },
  };
};
