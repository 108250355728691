import { clsx, type ClassValue } from 'clsx';
import { toRgb, type Colors } from '@mentimeter/ragnar-colors';
import { extendTailwindMerge, validators } from 'tailwind-merge/es5';

const twMerge = extendTailwindMerge({
  override: {
    classGroups: {
      opacity: [
        { opacity: [validators.isNumber, validators.isArbitraryValue] },
      ],
      'font-size': [
        {
          text: [
            validators.isNumber,
            validators.isArbitraryLength,
            (classPart: string) => /size-inherit/.test(classPart),
          ],
        },
      ],
    },
    conflictingClassGroups: {
      // In our tailwind.config we override the default font-size scale without line-height
      // I.e. font-size should no longer conflict with line-height
      // https://tailwindcss.com/docs/font-size
      'font-size': [],
    },
  },
  extend: {
    classGroups: {
      // We want to allow DEFAULT values for text and background color
      'text-color': [{ text: [''] }],
      'bg-color': [{ bg: [''] }],
    },
  },
});

export function cn(...classes: ClassValue[]) {
  return twMerge(clsx(classes));
}

export function getThemeColorVariables(colors: Colors) {
  const cssVariables = {} as Record<string, string>;
  for (const colorName in colors) {
    const color = colors[colorName as keyof Colors];
    const cssVariableName = `--color-${formatPaletteColorName(colorName)}`;
    try {
      cssVariables[cssVariableName] = getRGBAColor(color);
    } catch (error) {}
  }
  return cssVariables;
}

function formatPaletteColorName(colorName: string): string {
  const formattedColorName = colorName.replace(
    /([A-Z])|(\d+)/g,
    (_match: any, letter: string, number: number) => {
      if (number) {
        return `-${number}`;
      } else {
        return `-${letter.toLowerCase()}`;
      }
    },
  );
  return formattedColorName;
}

const getRGBAColor = (rgbString: string): string => {
  if (!rgbString) throw new Error();
  const { r, g, b, alpha } = toRgb(rgbString);
  return `rgb(${r} ${g} ${b} / ${alpha !== undefined ? alpha : 1})`;
};
