import { type EnvVarsT } from './global-env-vars-internal';

export function getPublicEnvVars() {
  // Only expose NEXT_PUBLIC_ env vars to the client.
  const envVars = Object.fromEntries(
    Object.entries(process.env).filter(([key]) =>
      key.startsWith('NEXT_PUBLIC_'),
    ),
  ) as EnvVarsT;
  return envVars;
}
