import * as React from 'react';
import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { Content } from '@radix-ui/react-dialog';
import type { DialogContentProps } from '@radix-ui/react-dialog';
import type { ComponentProps } from 'react';

/*
  Note 2023-04-17:
  While a large size looks good on mobile responsive mode in Chrome,
  on an actual smartphone there are browser elements that eat into the
  viewport (such as navigation elements).

  For example, on an iPhone 12 Pro the modal is not contained on the screen
  and overflows in both directions.

  This specific height value is tested on a few various apple devices and
  provides a good enough margin to not overflow on the these devices.

  If you test it yourself and see that you can increase this number
  without overflowing content, then go ahead!
*/
const MOBILE_MAX_HEIGHT = '86vh';

const contentShow = ({ translateY }: { translateY: string | number }) => ({
  '0%': { opacity: 0, transform: `translate(-50%, ${translateY}) scale(.96)` },
  '100%': { opacity: 1, transform: `translate(-50%,  ${translateY}) scale(1)` },
});

type DialogContentSize = 'small' | 'medium' | 'large';

const getDialogContentWidth = (size: DialogContentSize): string | undefined => {
  switch (size) {
    case 'small':
      return '500px';
    case 'medium':
      return '720px';
    case 'large':
      return '1080px';
    default:
      return undefined;
  }
};

interface DialogContentPrimitiveProps extends DialogContentProps {
  topAligned?: boolean;
  size?: DialogContentSize;
}

const dialogContentRule: Rule<DialogContentPrimitiveProps> = (
  { theme, topAligned = false, size = 'medium' },
  renderer,
) => ({
  position: 'fixed',
  top: topAligned ? `${theme.kosmosSpacing.space16}px` : '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflow: 'hidden',
  width: getDialogContentWidth(size),
  maxWidth: `calc(100vw - ${(theme.kosmosSpacing.space16 || 0) * 2}px)`,
  maxHeight: `calc(100vh - ${(theme.kosmosSpacing.space16 || 0) * 2}px)`,
  backgroundColor: theme.colors.bg,
  borderRadius: `${theme.kosmosBorderRadius['2xl']}px`,
  transform: `translate(-50%, ${!topAligned ? '-50%' : 0})`,
  zIndex: theme.zIndex.modal,

  '&:focus': {
    outline: 'none',
  },
  '@media screen and (max-width: 600px)': {
    top: topAligned ? `${theme.kosmosSpacing.space2}px` : '50%',
    maxWidth: `calc(100vw - ${theme.kosmosSpacing.space2 * 2}px)`,
    maxHeight: `${MOBILE_MAX_HEIGHT}`,
  },
  '@media (prefers-reduced-motion: no-preference)': {
    animation: renderer
      ? `${renderer.renderKeyframe(contentShow, {
          translateY: !topAligned ? '-50%' : 0,
        })} ${theme.durations[0]}s`
      : undefined,
  },
});

const DialogRadixContentStyled = styled(
  Content,
  {
    displayName: 'DialogRadixContentStyled',
  },
  true,
)(dialogContentRule, 'layout', 'flexbox', 'position', 'color', 'kosmosSpacing');

export interface ModalContentProps
  extends ComponentProps<typeof DialogRadixContentStyled> {
  dismissable?: boolean;
}

export const ModalContent = React.forwardRef(
  (
    {
      dismissable = true,
      onPointerDownOutside,
      onEscapeKeyDown,
      ...props
    }: ModalContentProps,
    forwardedRef,
  ) => {
    const handlePointerDownOutside: DialogContentProps['onPointerDownOutside'] =
      (e) => {
        if (insideToasts(e.target)) e.preventDefault();
        if (!dismissable) e.preventDefault();
        if (onPointerDownOutside) onPointerDownOutside(e);
      };
    const handleEscapeKeyDown: DialogContentProps['onEscapeKeyDown'] = (e) => {
      if (!dismissable) e.preventDefault();
      if (onEscapeKeyDown) onEscapeKeyDown(e);
    };
    return (
      <DialogRadixContentStyled
        ref={forwardedRef}
        aria-describedby={undefined}
        onPointerDownOutside={handlePointerDownOutside}
        onEscapeKeyDown={handleEscapeKeyDown}
        {...props}
      />
    );
  },
);

function insideToasts(element: any) {
  const toasts = document.getElementById('toasts');
  return toasts?.contains(element);
}
