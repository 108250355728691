import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import type { ReactNode } from 'react';

export interface ScreenReaderOnlyT {
  children: ReactNode;
}

export const screenReaderOnlyRule: Rule<ScreenReaderOnlyT> = () => ({
  border: ' 0 !important',
  clip: ' rect(1px, 1px, 1px, 1px) !important',
  clipPath: ' inset(50%) !important',
  height: ' 1px !important',
  overflow: ' hidden !important',
  margin: ' -1px !important',
  padding: ' 0 !important',
  position: ' absolute !important',
  width: ' 1px !important',
  whiteSpace: ' nowrap !important',
});

export const ScreenReaderOnly = styled('span')(screenReaderOnlyRule);
